import { generateClient } from "aws-amplify/api";
import { CREATE_STORE, DELETE_STORE, UPDATE_STORE, UPDATE_STROE_STORE } from "./query";

const client = generateClient();

export const addStore = async (RecipeInput: any) => {
  try {
    const response: any = await client.graphql({
      query: CREATE_STORE,
      variables: { input: RecipeInput },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteStore = async ({ PK, SK }: { PK: string; SK: string }) => {
  try {
    const data = await client.graphql({
      query: DELETE_STORE,
      variables: { PK, SK },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateStore = async (
  CategoryInput: any,
  PK: string | undefined,
  SK: string | undefined,
) => {
  try {
    const response: any = await client.graphql({
      query: UPDATE_STORE,
      variables: { PK: PK, SK: SK, input: CategoryInput },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const addOpeningHours = async (RecipeInput: any) => {
  try {
    const response: any = await client.graphql({
      query: UPDATE_STROE_STORE,
      variables: { input: RecipeInput },
    });
    return response;
  } catch (error) {
    return error;
  }
};
