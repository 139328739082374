/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getDriverReport, getErrorsPerOrder } from "../../Api/Dashboard/mutations";
import Table from "../../Blocks/Table/Table";
import { exportToExcel, formatDate } from "../../Common Functions/Function";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { driver_report_columns_name } from "./data";
import axios from "axios";

const DriverReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [totalSalesOrderReport, setTotalSalesOrderReport] = useState<any>({});
  const [dateError, setDateError] = useState("");
  const [startDate, setStartDate] = useState<Date | null | any>(null);
  const [endDate, setEndDate] = useState<Date | null | any>(null);
  const [isAPiCalled, setIsAPiCalled] = useState<boolean>(false);

  const { storeId } = useStoreId();

  function getLastWeekStartEnd() {
    const today = new Date();
    
    // Get current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = today.getDay();
    
    // Calculate last week's Monday
    const lastMonday = new Date(today);
    lastMonday.setDate(today.getDate() - dayOfWeek - 6); // Move back to last Monday
    lastMonday.setHours(0, 0, 0, 0); // Start of the day
  
    // Calculate last week's Sunday (6 days after last Monday)
    const lastSunday = new Date(lastMonday);
    lastSunday.setDate(lastMonday.getDate() + 6);
    lastSunday.setHours(23, 59, 59, 999); // End of the day
  
    return { lastMonday, lastSunday };
  }

  useEffect(() => {
      const { lastMonday, lastSunday } = getLastWeekStartEnd();
      const currentDate = lastSunday

    const startOfMonth = lastMonday;

    const formatDate = (date: Date) =>
      `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;

    setStartDate(formatDate(startOfMonth));
    setEndDate(formatDate(currentDate));
    validateDates(
      formatDate(startOfMonth),
      formatDate(currentDate),
      setDateError,
      fetchErrorsPerOrder
    );
  }, []);

  useEffect(() => {
    if (isAPiCalled) {
      validateDates(startDate, endDate, setDateError, fetchErrorsPerOrder);
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  const validateDates = (
    start: string | number | Date,
    end: string | number | Date,
    setDateError: React.Dispatch<React.SetStateAction<string>>,
    fetchErrorsPerOrder: (startDate: string, endDate: string) => void
  ) => {
    // If both dates are provided, check their validity
    if (start && end) {
      if (new Date(end) < new Date(start)) {
        setDateError(
          "End date must be greater than or equal to the start date."
        );
        return;
      } else {
        setDateError("");
        fetchErrorsPerOrder(start as string, end as string);
      }
    } else {
      setDateError("");
      console.warn(
        "One or both dates missing, calling API to return all data."
      );
      fetchErrorsPerOrder(start as string, end as string);
    }
  };

  const fetchErrorsPerOrder = async (startDate: any, endDate: any) => {
    try {
      const data = await getDriverReport(storeId, startDate, endDate);
      setIsAPiCalled(true);
      console.log("Data", data);

      const response = data.length > 0 && await getDistanceData(data);
      console.log('response', response);
      
      const updateData = data.map(
        (bucket: any) => {
          const date = new Date(bucket?._source?.createdDate).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
        //   const totalOrders = bucket.total_orders.value;
         
          

          return {
            date,
            driverName: bucket?._source?.driverName ? `${bucket?._source?.driverName}  (${bucket?._source?.driverEmail})` : 'NA',
            amount: bucket?._source?.amount,
            orderId : bucket?._source?.orderId,
            distance: bucket?._source?.distance,
            duration: bucket?._source?.duration
          };
        }
      );
      setTotalSalesOrderReport(updateData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setIsLoading(false);
    }
  };



  async function getDistanceData(transactions: any) {
    const osmBaseUrl = 'https://routing.openstreetmap.de/routed-car/route/v1/driving/';
  
    try {
      // Iterate over transactions and make a separate API call for each
      const updatedTransactions = await Promise.all(
        transactions.map(async (transaction: any) => {
          const origin = `${transaction._source.storeLong},${transaction._source.storeLat}`;
          const destination = `${transaction._source.customerLong},${transaction._source.customerLat}`;
          
          const url = `${osmBaseUrl}${origin};${destination}?overview=false&alternatives=true&steps=true`;
  
          try {
            // Fetch the distance and duration data for each transaction
            const response = await axios.get(url);
            
            // Handle the response and attach distance and duration data to the transaction
            const route = response.data.routes[0]; // Assuming the first route is the desired one
            
            if (route) {
              const distance = route.distance; // in meters
              const duration = route.duration; // in seconds
              const minutes = Math.floor(duration / 60);
              const seconds = Math.round(duration % 60);
              transaction._source.distance = `${(distance / 1000).toFixed(2)} km`;
              transaction._source.duration =  `${ minutes} min ${seconds} sec`;

              // transaction._source.distance = {
              //   text: `${(distance / 1000).toFixed(2)} km`, // Convert to km
              //   value: distance,
              // };
              // transaction._source.duration = {
              //   text: `${(duration / 60).toFixed(2)} mins`, // Convert to minutes
              //   value: duration,
              // };
            } else {
              transaction._source.distance = 'NA';
              transaction._source.duration = 'NA';
            }
          } catch (error) {
            console.error(`Error fetching data for transaction ${transaction._source.id}:`, error);
            transaction._source.distance = 'NA';
            transaction._source.duration = 'NA';
          }
  
          return transaction; // Return the updated transaction
        })
      );
  
      console.log('All transactions updated successfully', updatedTransactions);
      return updatedTransactions;
  
    } catch (error) {
      console.error("Error processing transactions:", error);
      return transactions; // Return original data if any error occurs
    }
  }

  return (
    <div>
      <div className="row my-4 ">
        <div className="col-auto">
          <h3 className="theme_color mb-0 fw-bolder">{"Driver Report"}</h3>
        </div>
        <div className="col-auto ms-auto">
          <button
            className="btn btn-outline-success"
            onClick={() => {
              exportToExcel(
                totalSalesOrderReport,
                ["date", "driverName", "orderId", "amount", "duration", "distance"],
                {
                  date: "Date",
                  driverName: "Name",
                  orderId: "OrderId",
                  amount: "Tip Amount",
                  duration: "Duration",
                  distance: "Distance",
                },
                "Weekly_Driver_Tips_Report"
              );
            }}
            type="button"
          >
            Export
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div className="col-sm-12 col-md-6 col-lg-5 col-xxl-4 mb-4">
          <label className="form-label" htmlFor="dateRange">
            Filter by date range
          </label>
          <div
            id="dateRange"
            className="d-flex flex-column flex-md-row justify-content-between"
          >
            <div className="form-group me-md-2 w-100">
              <label htmlFor="startDate">Start Date</label>

              <DatePicker
                selected={startDate}
                onChange={(date: any) =>
                  setStartDate(date ? formatDate(date) : null)
                }
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                className="form-control"
                aria-label="Start date"
                id="startDate"
                placeholderText="YYYY-MM-DD"
                isClearable
              />
            </div>
            <div className="form-group w-100">
              <label htmlFor="endDate">End Date</label>

              <DatePicker
                selected={endDate}
                onChange={(date: any) =>
                  setEndDate(date ? formatDate(date) : null)
                }
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                className="form-control"
                aria-label="End date"
                id="endDate"
                placeholderText="YYYY-MM-DD"
                isClearable
              />
            </div>
          </div>
          {dateError && <div className="text-danger mt-2">{dateError}</div>}
        </div>
      </div>
      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={driver_report_columns_name}
          data={totalSalesOrderReport}
          isLoading={isLoading}
          Pagination={{
            from: 0,
            size: 50,
            page_no: 1,
            pageSize: 50,
            total_pages: 0,
          }}
        />
      </div>
    </div>
  );
};

export default DriverReport;
