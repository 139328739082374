import axios from "axios";
import { getAuthToken } from "../utils";

interface SearchData {
  fields: string;
  value: string | { from: string; to: string };
}

interface Pagination {
  from: number;
  size: number;
}

interface Pagination {
  from: number;
  size: number;
}
export const searchOrders = async (
  indexName: string,
  data: SearchData[],
  pagination: Pagination,
  storeId: string | null,
): Promise<any> => {
  if (!Array.isArray(data)) {
    throw new Error(
      "Data must be an array of objects with fields and values properties",
    );
  }

  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const userIndex = process.env.REACT_APP_STACK
    ? `user${process.env.REACT_APP_STACK}`
    : "user";

  const mustClauses = data
    .filter((item) => {
      if (typeof item.value === "object") {
        return item.value.from !== "" || item.value.to !== "";
      }
      return item.value.trim() !== "";
    })
    .map((item) => {
      if (typeof item.value === "object" && item.fields !== "createdDate") {
        const rangeQuery: any = {};
        if (item.value.from !== "") {
          rangeQuery["gte"] = item.value.from;
        }
        if (item.value.to !== "") {
          rangeQuery["lte"] = item.value.to;
        }
        return {
          range: {
            [item.fields]: rangeQuery,
          },
        };
      } else if (item.fields === "createdDate") {
        return {
          query_string: {
            fields: [item.fields],
            query: `${item.value}`,
          },
        };
      } else if (item.fields === "id") {
        return {
          query_string: {
            fields: [`${item.fields}.keyword`],
            query: `${item.value}`,
          },
        };
      } else {
        return {
          query_string: {
            fields: [item.fields],
            query: `*${item.value}*`,
          },
        };
      }
    });

  let val = { term: { storeId: storeId } };

  const queryBody = {
    from: pagination.from,
    size: pagination.size,
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    query: {
      bool: {
        must: mustClauses.length > 0 ? mustClauses : [{ match_all: {} }],
        filter: [{ match: { isDeleted: "false" } }, val],
      },
    },
  };

  try {
    const orderResponse = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    const orders = orderResponse.data?.hits?.hits || [];

    const enrichedOrders = await Promise.all(
      orders.map(async (order: any) => {
        const customerId = order._source?.customerDetails?.customerId?.trim();
        if (customerId) {
          const userQuery = {
            query: {
              bool: {
                must: [
                  {
                    term: {
                      "userId.keyword": customerId,
                    },
                  },
                ],
              },
            },
          };

          const userResponse = await axios.post(
            `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${userIndex}/_search`,
            userQuery,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: await getAuthToken(),
              },
            },
          );

          const userHit = userResponse?.data?.hits?.hits[0];
          const customerName = userHit?._source?.fullName;

          if (customerName) {
            order._source.customerName = customerName;
          }
        }
        return order;
      }),
    );

    return {
      total: orderResponse.data?.hits?.total,
      max_score: orderResponse.data?.hits?.max_score,
      hits: enrichedOrders,
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getOrderByIdAndStatus = async (
  query: string,
  indexName: string,
  userId: string | undefined,
  status: string[],
  orderIdInput: string,
  pagination: Pagination,
  startDate: string,
  endDate: string,
): Promise<any> => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const userIndex = process.env.REACT_APP_STACK
    ? `user${process.env.REACT_APP_STACK}`
    : "user";

  const mustClauses = [];

  if (status && status.length > 0 && !status.every((s) => s === "")) {
    mustClauses.push({
      terms: {
        "status.keyword": status.filter((s) => s !== ""),
      },
    });
  }

  if (orderIdInput && orderIdInput.trim().length > 0) {
    mustClauses.push({
      wildcard: {
        id: `*${orderIdInput.trim()}*`,
      },
    });
  }

  if (startDate && endDate) {
    if (new Date(endDate) >= new Date(startDate)) {
      mustClauses.push({
        range: {
          createdDate: {
            gte: startDate,
            lte: endDate,
          },
        },
      });
    } else {
      console.warn("End date must be greater than or equal to the start date.");
    }
  } else {
    mustClauses.push({
      match_all: {},
    });
  }

  const queryBody = {
    from: pagination.from,
    size: pagination.size,
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    query: {
    bool: {
      must: [
        {
          "match_all": {}
        }
      ],
      filter: [
        {
          term: {
            "IdUser.keyword": userId,
          }
        }
      ]
    }
  }
    // query: {
    //   bool: {
    //     must: mustClauses,
    //     filter: [
    //       {
    //         match: {
    //           IdUser: userId,
    //         },
    //       },
    //     ],
    //   },
    // },
  };

  try {
    const orderResponse = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    const orders = orderResponse.data?.hits?.hits || [];

    const enrichedOrders = await Promise.all(
      orders.map(async (order: any) => {
        const customerId = order._source?.customerDetails?.customerId?.trim();
        if (customerId) {
          const userQuery = {
            query: {
              bool: {
                must: [
                  {
                    term: {
                      "userId.keyword": customerId,
                    },
                  },
                ],
              },
            },
          };

          const userResponse = await axios.post(
            `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${userIndex}/_search`,
            userQuery,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: await getAuthToken(),
              },
            },
          );

          const userHit = userResponse?.data?.hits?.hits[0];
          const customerName = userHit?._source?.fullName;

          if (customerName) {
            order._source.customerName = customerName;
          }
        }
        return order;
      }),
    );

    return {
      total: orderResponse.data?.hits?.total,
      max_score: orderResponse.data?.hits?.max_score,
      hits: enrichedOrders,
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getDriverOrderByIdAndStatus = async (
  query: string,
  indexName: string,
  userId: string | undefined,
  status: string[],
  orderIdInput: string,
  pagination: Pagination,
  startDate: string,
  endDate: string,
): Promise<any> => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const userIndex = process.env.REACT_APP_STACK
    ? `user${process.env.REACT_APP_STACK}`
    : "user";

  const mustClauses = [];

  if (status && status.length > 0 && !status.every((s) => s === "")) {
    mustClauses.push({
      terms: {
        "status.keyword": status.filter((s) => s !== ""),
      },
    });
  }

  if (orderIdInput && orderIdInput.trim().length > 0) {
    mustClauses.push({
      wildcard: {
        id: `*${orderIdInput.trim()}*`,
      },
    });
  }

  if (startDate && endDate) {
    if (new Date(endDate) >= new Date(startDate)) {
      // Ensure endDate is greater or equal to startDate
      mustClauses.push({
        range: {
          createdDate: {
            gte: startDate, // Start date should be greater than or equal to this
            lte: endDate, // End date should be less than or equal to this
          },
        },
      });
    } else {
      console.warn("End date must be greater than or equal to the start date.");
    }
  }

  const queryBody = {
    from: pagination.from,
    size: pagination.size,
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    query: {
      bool: {
        must: mustClauses,
        filter: [
          {
            match: {
              "driverId.keyword": userId,
            },
          },
        ],
      },
    },
  };

  try {
    const orderResponse = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    const orders = orderResponse.data?.hits?.hits || [];

    const enrichedOrders = await Promise.all(
      orders.map(async (order: any) => {
        const customerId = order._source?.customerDetails?.customerId?.trim();
        if (customerId) {
          const userQuery = {
            query: {
              bool: {
                must: [
                  {
                    term: {
                      "userId.keyword": customerId,
                    },
                  },
                ],
              },
            },
          };

          const userResponse = await axios.post(
            `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${userIndex}/_search`,
            userQuery,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: await getAuthToken(),
              },
            },
          );

          const userHit = userResponse?.data?.hits?.hits[0];
          const customerName = userHit?._source?.fullName;

          if (customerName) {
            order._source.customerName = customerName;
          }
        }
        return order;
      }),
    );

    return {
      total: orderResponse.data?.hits?.total,
      max_score: orderResponse.data?.hits?.max_score,
      hits: enrichedOrders,
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getTotalOrderByIdAndStatus = async (
  query: string,
  indexName: string,
  userId: string | undefined,
): Promise<any> => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const userIndex = process.env.REACT_APP_STACK
    ? `user${process.env.REACT_APP_STACK}`
    : "user";

  const queryBody = {
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    size: 1000,

    query: {
      bool: {
        must: [
          {
            match: {
              IdUser: userId,
            },
          },
        ],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    const orders = response.data?.hits?.hits || [];

    const enrichedOrders = await Promise.all(
      orders.map(async (order: any) => {
        const customerId = order._source?.customerDetails?.customerId?.trim();
        if (customerId) {
          const userQuery = {
            query: {
              bool: {
                must: [
                  {
                    term: {
                      "userId.keyword": customerId,
                    },
                  },
                ],
              },
            },
          };

          const userResponse = await axios.post(
            `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${userIndex}/_search`,
            userQuery,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: await getAuthToken(),
              },
            },
          );

          const userHit = userResponse?.data?.hits?.hits[0];
          const customerName = userHit?._source?.fullName;

          if (customerName) {
            order._source.customerName = customerName;
          }
        }
        return order;
      }),
    );

    return {
      total: response.data?.hits?.total,
      max_score: response.data?.hits?.max_score,
      hits: enrichedOrders,
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getDriverTotalOrderByIdAndStatus = async (
  query: string,
  indexName: string,
  userId: string | undefined,
): Promise<any> => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const userIndex = process.env.REACT_APP_STACK
    ? `user${process.env.REACT_APP_STACK}`
    : "user";

  const queryBody = {
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    size: 1000,

    query: {
      bool: {
        must: [
          {
            match: {
              "driverId.keyword": userId,
            },
          },
        ],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    const orders = response.data?.hits?.hits || [];

    const enrichedOrders = await Promise.all(
      orders.map(async (order: any) => {
        const customerId = order._source?.customerDetails?.customerId?.trim();
        if (customerId) {
          const userQuery = {
            query: {
              bool: {
                must: [
                  {
                    term: {
                      "userId.keyword": customerId,
                    },
                  },
                ],
              },
            },
          };

          const userResponse = await axios.post(
            `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${userIndex}/_search`,
            userQuery,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: await getAuthToken(),
              },
            },
          );

          const userHit = userResponse?.data?.hits?.hits[0];
          const customerName = userHit?._source?.fullName;

          if (customerName) {
            order._source.customerName = customerName;
          }
        }
        return order;
      }),
    );

    return {
      total: response.data?.hits?.total,
      max_score: response.data?.hits?.max_score,
      hits: enrichedOrders,
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getOrderByUserId = async (
  query: string,
  indexName: string,
  userId: string | undefined,
): Promise<any> => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const userIndex = process.env.REACT_APP_STACK
    ? `user${process.env.REACT_APP_STACK}`
    : "user";

  const queryBody = {
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    query: {
      bool: {
        must: [{ match: { IdUser: userId } }],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    const orders = response.data?.hits?.hits || [];

    const enrichedOrders = await Promise.all(
      orders.map(async (order: any) => {
        const customerId = order._source?.customerDetails?.customerId?.trim();
        if (customerId) {
          const userQuery = {
            query: {
              bool: {
                must: [
                  {
                    term: {
                      "userId.keyword": customerId,
                    },
                  },
                ],
              },
            },
          };

          const userResponse = await axios.post(
            `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${userIndex}/_search`,
            userQuery,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: await getAuthToken(),
              },
            },
          );

          const userHit = userResponse?.data?.hits?.hits[0];
          const customerName = userHit?._source?.fullName;

          if (customerName) {
            order._source.customerName = customerName;
          }
        }
        return order;
      }),
    );

    return {
      total: response.data?.hits?.total,
      max_score: response.data?.hits?.max_score,
      hits: enrichedOrders,
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getOrderById = async (
  query: string,
  indexName: string,
  id: string | undefined,
): Promise<any> => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const userIndex = process.env.REACT_APP_STACK
    ? `user${process.env.REACT_APP_STACK}`
    : "user";

  const queryBody = {
    sort: [
      {
        createdDate: {
          order: "desc",
        },
      },
    ],
    query: {
      bool: {
        must: [{ match: { "id.keyword": id } }],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    const orders = response.data?.hits?.hits || [];

    const enrichedOrders = await Promise.all(
      orders.map(async (order: any) => {
        const customerId = order._source?.customerDetails?.customerId?.trim();
        if (customerId) {
          const userQuery = {
            query: {
              bool: {
                must: [
                  {
                    term: {
                      "userId.keyword": customerId,
                    },
                  },
                ],
              },
            },
          };

          const userResponse = await axios.post(
            `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${userIndex}/_search`,
            userQuery,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: await getAuthToken(),
              },
            },
          );

          const userHit = userResponse?.data?.hits?.hits[0];
          const customerName = userHit?._source?.fullName;

          if (customerName) {
            order._source.customerName = customerName;
          }
        }
        return order;
      }),
    );

    return {
      total: response.data?.hits?.total,
      max_score: response.data?.hits?.max_score,
      hits: enrichedOrders,
    };
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};
