import React, { useState, useEffect } from "react";
import {
  APIProvider,
  Map,
  Marker,
  useMapsLibrary,
  useMap,
} from "@vis.gl/react-google-maps";

const MovingMarkerWithRoute: React.FC = () => {
  const maps = useMapsLibrary("routes");
  const map = useMap();

  const [route, setRoute] = useState<google.maps.LatLng[]>([]);
  const [position, setPosition] = useState<google.maps.LatLng | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [rotation, setRotation] = useState<number>(0);

  useEffect(() => {
    if (!maps || !map) return;

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({ map });

    const startPoint = new google.maps.LatLng(22.74377, 75.89003);
    const endPoint = new google.maps.LatLng(22.6948, 75.890688);

    directionsService.route(
      {
        origin: startPoint,
        destination: endPoint,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result :any , status : any) => {
        if (status === "OK" && result?.routes.length > 0) {
          directionsRenderer.setDirections(result);
          const path = result.routes[0].overview_path;
          setRoute(path);
          setPosition(path[0]);
        }
      }
    );

    return () => directionsRenderer.setMap(null);
  }, [maps, map]);

  useEffect(() => {
    if (route.length === 0) return;

    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= route.length - 1) {
          clearInterval(interval);
          return prev;
        }
        return prev + 1;
      });
    }, 500);

    return () => clearInterval(interval);
  }, [route]);

  useEffect(() => {
    if (route.length > 1 && progress < route.length - 1) {
      setPosition(route[progress]);

      // Calculate angle between two points
      const pointA = route[progress];
      const pointB = route[progress + 1];
      const angle =
        (Math.atan2(pointB.lng() - pointA.lng(), pointB.lat() - pointA.lat()) *
          180) /
        Math.PI;

      setRotation(angle); // Adjusting bike direction dynamically
    }
  }, [progress, route]);

  return position ? (
    <Marker
      position={position}
      icon={{
        url: "/motorcycling.png",
        scaledSize: new google.maps.Size(30, 30), // Smaller icon
        anchor: new google.maps.Point(15, 15), // Adjust anchor to center
        rotation: rotation, // Apply rotation dynamically
      }}
    />
  ) : null;
};

const DriverTracking: React.FC = () => {
  return (
    <APIProvider apiKey="AIzaSyCvmDp2929dMBatKtwdMJu2iZL7I5MX01E">
      <Map
        defaultCenter={{ lat: 22.7, lng: 75.89 }}
        defaultZoom={14}
        style={{ width: "100%", height: "500px" }}
      >
        <MovingMarkerWithRoute />
      </Map>
    </APIProvider>
  );
};

export default DriverTracking;
