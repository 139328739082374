const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "id",
    label: "Order ID",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Order ID",
    },
  },
  {
    field: "customerName",
    label: "Customer Name",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Customer Name",
    },
  },
  {
    field: "commonOrderId",
    label: "Parent Id",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Parent Id",
    },
  },
  {
    field: "orderType",
    label: "Order Type",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Order Type",
    },
  },

  {
    field: "status",
    label: "Order Status",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Order Status",
    },
  },
  {
    field: "paymentStatus",
    label: "Payment Status",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Payment Status",
    },
  },
  {
    field: "total",
    label: "Order Amount",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Order Amount",
    },
  },
  {
    field: "createdDate",
    label: "Order Date",
    type: "date",
    sub_header: {
      inputtype: "input",
      placeholder: "Created At",
    },
  },
  {
    field: "driverId",
    label: "Assigned To",
    type: "select",
  },
  {
    field: "Details",
    label: "Details",
    type: "Details",
  },
];

export { columns_name };
