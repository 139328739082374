import { ReactComponent as Group } from "../../Asset/Group 236.svg";
import { ReactComponent as Search } from "../../Asset/search.svg";
import { ReactComponent as Up } from "../../Asset/arrow-circle-up.svg";
import { ReactComponent as Down } from "../../Asset/arrow-circle-down.svg";
import { useCallback, useEffect, useState } from "react";
import { OrderData } from "./data";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { processCustomerData, processOrderResponse } from "./utils";
import FormEffect from "../../Effects/FormEffect";
import moment from "moment";
import user from "../../Asset/user.jpg";
import { getCustomerById } from "../../Api/Search/Customer/Customer";
import {
  getOrderByIdAndStatus,
  getTotalOrderByIdAndStatus,
} from "../../Api/Search/Orders/Orders";
import {
  capitalizeFirstLetter,
  forceDownloadPdf,
  formatDate,
  handlePaginations,
  isValidJSON,
} from "../../Common Functions/Function";
import { Routers } from "../../Routes/Routes";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { downloadInvoice } from "../../Api/Invoice/mutation";

const CustomerDetails = () => {
  console.log('ACCEPTED')
  const [openAccordion, setOpenAccordion] = useState(null);
  const { userId } = useParams();
  console.log('userId', userId);
  const [customerDetails, setCustomerDetails] = useState<any | undefined>();
  const [orderDetails, setOrderDetials] = useState<OrderData[]>([]);
  const [orderCount, setOrderCount] = useState(0);
  const [orderIdInput, setorderIdInput] = useState("");
  const [orderStatus, setorderStatus] = useState<any>("");
  const [loader, setLoader] = useState(false);
  const [total, setTotal] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [CompleteOrder, setCompletedOrder] = useState(0);
  const [failedOrder, setfailedOrder] = useState(0);
  const [Pagination, setPagination] = useState({
    from: 0,
    size: 5,
    page_no: 1,
    pageSize: 5, //per_page_records
    total_pages: 0,
  });

  const toggleAccordion = (orderId: any) => {
    setOpenAccordion(orderId === openAccordion ? null : orderId);
  };

  const status = (status: string) => {
    if (status === "success") {
      return "text-success";
    } else if (status === "ready for delivery") {
      return "text-danger";
    } else {
      return "text-warning";
    }
  };

  const getDetails = useCallback(async () => {
    setLoader(true);
    await getCustomerById("", "user", userId)
      .then(async (res) => {
        const response = await processCustomerData(res);
        setCustomerDetails(response?.modifiedData[0]);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
        setLoader(false);
      });
  }, [userId]);

  const getOrderDetails = useCallback(
    async (startDate: string, endDate: string) => {
      setIsLoading(true);
      await getOrderByIdAndStatus(
        "",
        "order",
        userId,
        [orderStatus],
        orderIdInput,
        Pagination,
        startDate,
        endDate
      )
        .then(async (res) => {
          const response = await processOrderResponse(res);
          setOrderDetials(response?.modifiedData);
          setTotal(response?.total?.hits?.total?.value);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching user details:", error);
        });
    },
    [userId, orderStatus, orderIdInput, Pagination]
  );

  const getTotalOrderDetails = useCallback(async () => {
    setIsLoading(true);
    await getTotalOrderByIdAndStatus("", "order", userId)
      .then(async (res) => {
        const response = await processOrderResponse(res);
        setCompletedOrder(response?.successfulOrderCount);
        setOrderCount(response?.total);
        setfailedOrder(response?.failedOrderCount);
        setTotal(response?.total);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching user details:", error);
      });
  }, [userId]);

  useEffect(() => {
    getTotalOrderDetails();
  }, [getTotalOrderDetails]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    getOrderDetails(startDate, endDate);
    // eslint-disable-next-line
  }, [getOrderDetails]);

  const OrderStatusChange = (status: string) => {
    setorderStatus(status);
  };

  const handlePagination = (direction: any) => {
    handlePaginations(setPagination, direction);
  };

  function calculateRefundOrderDetails(order: { refundOrderDetails: any[] }) {
    if (!order || !order.refundOrderDetails) {
      return 0;
    }
    const refundTotal = order.refundOrderDetails.reduce((acc, element) => {
      return acc + (element?.salesPrice || 0) * (element?.quantity || 0);
    }, 0);
    return refundTotal;
  }

  function calculateRefundOrderDetailsVat(order: {
    refundOrderDetails: { salesPrice?: number; quantity?: number }[];
  }): number {
    if (!order || !order.refundOrderDetails) {
      return 0;
    }

    const refundTotal = order.refundOrderDetails.reduce((acc, item) => {
      const salesPrice = item?.salesPrice ?? 0;
      const quantity = item?.quantity ?? 0;
      return acc + salesPrice * quantity;
    }, 0);

    const calculatedValue = refundTotal * 0.15;
    return calculatedValue;
  }

  function calculateRefundOrderDetailsTotal(order: {
    refundOrderDetails: any[];
  }) {
    if (!order || !order.refundOrderDetails) {
      return 0;
    }
    const refundTotal = order.refundOrderDetails.reduce((acc, element) => {
      return acc + (element?.salesPrice || 0) * (element?.quantity || 0);
    }, 0);
    const calculatedValue = refundTotal * 0.15;
    let total = refundTotal + calculatedValue;
    return total;
  }

  const [dateError, setDateError] = useState("");
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  // Handler functions to update state

  const validateDates = (
    start: string | number | Date,
    end: string | number | Date,
    setDateError: React.Dispatch<React.SetStateAction<string>>,
    getOrderDetails: (startDate: string, endDate: string) => void
  ) => {
    // If both dates are provided, check their validity
    if (start && end) {
      if (new Date(end) < new Date(start)) {
        setDateError(
          "End date must be greater than or equal to the start date."
        );
        return;
      } else {
        setDateError("");
        getOrderDetails(start as string, end as string);
      }
    } else {
      setDateError("");
      console.warn(
        "One or both dates missing, calling API to return all data."
      );
      getOrderDetails(start as string, end as string);
    }
  };

  useEffect(() => {
    validateDates(startDate, endDate, setDateError, getOrderDetails);
    // eslint-disable-next-line
  }, [startDate, endDate, getOrderDetails]);

  const [downloadInvoiceLoader, setDownloadInvoiceLoader] = useState(false);

  const DownloadInvoice = (item: any) => {
    setDownloadInvoiceLoader(true);
    let OrderInvoiceInput = {
      SK: item?.SK,
      PK: item?.PK,
      entityName: item?.entityName,
      id: item?.id,
    };

    downloadInvoice(OrderInvoiceInput)
      .then((res) => {
        const result = isValidJSON(res?.data?.orderInvoice)
          ? JSON.parse(res?.data?.orderInvoice)
          : res?.data?.orderInvoice;

        if (result?.statusCode === 200) {
          let response = JSON.parse(result.body);
          const pdfUrl = response?.url;

          if (pdfUrl) {
            forceDownloadPdf(pdfUrl);
            setDownloadInvoiceLoader(false);
          } else {
            setDownloadInvoiceLoader(false);
            console.log("URL is not available.");
          }
        } else {
          setDownloadInvoiceLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log('orderDetails', orderDetails);
  }, [orderDetails])
  

  return (
    <div className="customer_details_table">
      <div className="row mb-4 mt-4  justify-content-between">
        <div className="col-auto d-flex align-items-center">
          <h3 className="theme_color fw-bold m-0">
            <Link
              className="text-decoration-none theme_color"
              to={Routers?.customer}
            >
              <i className="bi bi-chevron-left"></i>
            </Link>
            {"Customer Details"}
          </h3>
        </div>
        <div className="col-auto d-flex justify-content-end ms-auto">
          <Group />
        </div>
      </div>
      {loader ? (
        <FormEffect />
      ) : (
        <>
          <div className="mb-4 card user_details">
            <div className="row g-0">
              <div className="col-md-4 user_details_section justify-content-center ">
                <div className="card-body h-100 d-flex align-items-center gap-2 ">
                  <img src={user} alt="profile" className="prfile" />
                  <div className="overflow-hidden">
                    <h4 className="fw-bold">{customerDetails?.fullName}</h4>
                    <span>{customerDetails?.email}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex user_details_section justify-content-center">
                <div className="card-body">
                  <h4 className="fw-bold mb-3">Personal Information</h4>
                  <div className="d-flex justify-content-between">
                    <p className="fw-bold">Contact Number</p>
                    <p className="fw-bold">{customerDetails?.phone}</p>
                  </div>
                  {/* <div className="d-flex justify-content-between">
                    <p className="fw-bold">Gender</p>
                    <p className="fw-bold">Male</p>
                  </div> */}
                  {/* <div className="d-flex justify-content-between">
                    <p className="fw-bold">Date of birth</p>
                    <p className="fw-bold">1 Jan, 1985</p>
                  </div> */}
                  <div className="d-flex justify-content-between">
                    <p className="fw-bold">Member Since</p>
                    <p className="fw-bold">
                      {moment(customerDetails?.createdDate).format(
                        "DD MMM, YYYY"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-center">
                <div className="card-body">
                  <h4 className="fw-bold mb-3">Delivery Address</h4>
                  {customerDetails?.addressList
                    ?.filter((item: any) => item.isDefault)
                    ?.map((item: any) => (
                      <div key={item?.addressName}>
                        <p className="m-0 fw-bold">
                          {item?.addressName}, {item?.aptNumber},{" "}
                          {item?.address}
                        </p>
                      </div>
                    ))}
                  <br />
                  <div className="row row-cols-1 row-cols-md-3 g-3">
                    <div className="col">
                      <p className="m-0 fw-bold">{orderCount}</p>
                      <p className="fw-bold text-nowrap">Total Order</p>
                    </div>
                    <div className="col">
                      <p className="m-0 fw-bold ">{CompleteOrder}</p>
                      <p className="fw-bold text-nowrap">Completed</p>
                    </div>
                    <div className="col">
                      <p className="m-0 fw-bold ">{failedOrder}</p>
                      <p className="fw-bold text-nowrap">Cancelled</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-bottom mt-2"></div>
            <div className="row g-0">
              <div className="col-md-12 d-flex justify-content-center">
                <div className="card-body d-flex align-items-center gap-2">
                  <button
                    className={`btn btn-outline-primary ${orderStatus === "" ? "active" : ""}`}
                    type="button"
                    onClick={() => OrderStatusChange("")}
                  >
                    All Order
                  </button>
                  <button
                    className={`btn btn-outline-primary ${orderStatus === "Completed" ? "active" : ""}`}
                    type="button"
                    onClick={() => OrderStatusChange("Completed")}
                  >
                    Completed
                  </button>
                  <button
                    className={`btn btn-outline-primary ${orderStatus === "failed" ? "active" : ""}`}
                    type="button"
                    onClick={() => OrderStatusChange("failed")}
                  >
                    Canceled
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4 row filter_customer align-items-end">
            <div className="col-sm-12 col-md-6 col-lg-7 col-xxl-8 mb-3 mb-md-0">
              <label className="form-label" htmlFor="orderId">
                Filter by Order ID
              </label>
              <div className="filter_wrap">
                <Search />
                <input
                  type="search"
                  className="form-control theme_color"
                  id="orderId"
                  aria-label="Search by order ID"
                  placeholder="Search by order ID"
                  onChange={(e) => setorderIdInput(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-5 col-xxl-4">
              <label className="form-label" htmlFor="dateRange">
                Filter by date range
              </label>
              <div
                id="dateRange"
                className="d-flex flex-column flex-md-row justify-content-between"
              >
                <div className="form-group me-md-2 w-100">
                  <label htmlFor="startDate">Start Date</label>

                  <DatePicker
                    selected={startDate}
                    onChange={(date: any) =>
                      setStartDate(date ? formatDate(date) : null)
                    }
                    dateFormat="yyyy-MM-dd"
                    maxDate={new Date()}
                    className="form-control"
                    aria-label="Start date"
                    id="startDate"
                    placeholderText="YYYY-MM-DD"
                    isClearable
                  />
                </div>
                <div className="form-group w-100">
                  <label htmlFor="endDate">End Date</label>

                  <DatePicker
                    selected={endDate}
                    onChange={(date: any) =>
                      setEndDate(date ? formatDate(date) : null)
                    }
                    dateFormat="yyyy-MM-dd"
                    maxDate={new Date()}
                    className="form-control"
                    aria-label="End date"
                    id="endDate"
                    placeholderText="YYYY-MM-DD"
                    isClearable
                  />
                </div>
              </div>
              {dateError && <div className="text-danger mt-2">{dateError}</div>}
            </div>
          </div>
          <div className=" mb-4 row bg-white">
            <div className="col-md-12">
              <div className="table-responsive ">
                <table className="table theme_color">
                  <thead>
                    <tr>
                      <th scope="col">ORDER ID</th>
                      <th scope="col">ORDER TYPE</th>
                      <th scope="col">CREATED</th>
                      <th scope="col">TOTAL</th>
                      <th scope="col">PAYMENT</th>
                      <th scope="col">STATUS</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading
                      ? [...Array(5)].map((_, rowIndex) => (
                          <tr key={rowIndex}>
                            {[...Array(5)].map((val, cellIndex) => (
                              <td key={cellIndex}>
                                <div className="shimmer-cell"></div>
                              </td>
                            ))}
                          </tr>
                        ))
                      : orderDetails &&
                        orderDetails?.map((order: any) => (
                          <React.Fragment key={order?.id}>
                            <tr
                              data-bs-toggle="collapse"
                              data-bs-target={`#accordion-collapse-${order?.id}`}
                              className={`accordion-toggle ${openAccordion === order?.id ? "open_sub_container" : ""}`}
                              onClick={() => toggleAccordion(order?.id)}
                            >
                              <td>{order?.id}</td>
                              <td>{order?.orderType}</td>

                              <td>
                                {moment(order?.createdDate).format(
                                  "DD MMM, YYYY"
                                )}
                              </td>
                              <td>{order?.total.toFixed(2)}</td>
                              <td>{"CC"}</td>
                              <td className={status(order?.status)}>
                                {order?.status
                                  ? capitalizeFirstLetter(order?.status)
                                  : "Pending"}
                              </td>
                              <td>
                                {openAccordion === order.id ? <Up /> : <Down />}
                              </td>
                            </tr>
                            <tr
                              className={`collapse accordion-collapse ${
                                openAccordion === order.id
                                  ? "show open_sub_container_content"
                                  : ""
                              }`}
                              id={`accordion-collapse-${order.id}`}
                            >
                              <td colSpan={7} className="p-0">
                                <table
                                  className="table  theme_color"
                                  style={{
                                    backgroundColor: "rgba(235, 234, 228, 0.4)",
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th scope="col">Name</th>
                                      <th scope="col">PRICE</th>
                                      <th scope="col">QTY</th>
                                      <th scope="col">Disc.</th>
                                      <th scope="col">Total</th>
                                      <th
                                        scope="col"
                                        className="cursor-pointer"
                                        onClick={() => DownloadInvoice(order)}
                                      >
                                        <i className="bi bi-printer"></i>{" "}
                                        {downloadInvoiceLoader
                                          ? "Downloading..."
                                          : "PRINT"}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {order?.orderDetails?.map(
                                      (item: any, index: number) => (
                                        <tr key={index}>
                                          <td>{item?.productName}</td>
                                          <td>
                                            (R)
                                            {Number(item?.salesPrice).toFixed(
                                              2
                                            )}
                                          </td>
                                          <td>{item?.quantity}</td>
                                          <td className="text-danger">
                                            {Number(item?.discount)
                                              ? Number(item?.discount).toFixed(
                                                  2
                                                )
                                              : 0}
                                            %
                                          </td>
                                          <td>
                                            {Number(
                                              item?.salesPrice * item?.quantity
                                            ).toFixed(2)}
                                          </td>
                                          <td>
                                            <i className="bi bi-three-dots"></i>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                    <tr>
                                      <td colSpan={2}></td>
                                      <td>
                                        <p>Subtotal</p>
                                        <p>Delivery</p>
                                        <p>Discount</p>
                                        <p>VAT</p>
                                        <p>Tip</p>
                                        <p>Total</p>
                                        <b>Order Delivery Address</b>
                                      </td>
                                      <td colSpan={1}></td>
                                      <td>
                                        <p>{order.subtotal}</p>
                                        <p className="text-danger">
                                          {order.deliveryCharge.toFixed(2)}
                                        </p>
                                        <p>
                                          {Number(order?.discount)
                                            ? Number(order?.discount).toFixed(2)
                                            : 0}
                                        </p>
                                        <p>
                                          {(
                                            parseFloat(order?.subtotal) * 0.15
                                          ).toFixed(2)}
                                        </p>
                                        <p>{order.tip.toFixed(2)}</p>

                                        <p>{order.total.toFixed(2)}</p>
                                        <b>
                                          {`Address: ${order?.addressList?.[0]?.addressName},${order?.addressList?.[0]?.aptNumber},${order?.addressList?.[0]?.address}`}
                                        </b>
                                      </td>
                                      <td colSpan={1}> </td>
                                    </tr>
                                  </tbody>
                                </table>
                                {order?.refundOrderDetails?.length > 0 && (
                                  <span className="p-2 ">
                                    {"Refund Order Details"}
                                  </span>
                                )}
                                {order?.refundOrderDetails?.length > 0 && (
                                  <table
                                    className="table  theme_color"
                                    style={{
                                      backgroundColor:
                                        "rgba(235, 234, 228, 0.4)",
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">PRICE</th>
                                        <th scope="col">QTY</th>
                                        <th scope="col">Disc.</th>
                                        <th scope="col">Total</th>
                                        {/* <th scope="col">
                                          <i className="bi bi-printer"></i>{" "}
                                          PRINT
                                        </th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {order?.refundOrderDetails?.map(
                                        (item: any, index: number) => (
                                          <tr key={index}>
                                            <td>{item?.productName}</td>
                                            <td>
                                              (R)
                                              {Number(item?.salesPrice).toFixed(
                                                2
                                              )}
                                            </td>
                                            <td>{item?.quantity}</td>
                                            <td className="text-danger">
                                              {Number(item?.discount)
                                                ? Number(
                                                    item?.discount
                                                  ).toFixed(2)
                                                : 0}
                                              %
                                            </td>
                                            <td>
                                              {Number(
                                                item?.salesPrice *
                                                  item?.quantity
                                              ).toFixed(2)}
                                            </td>
                                            <td>
                                              <i className="bi bi-three-dots"></i>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                      <tr>
                                        <td colSpan={2}></td>
                                        <td>
                                          <p>Subtotal</p>

                                          <p>VAT</p>
                                          <p>Total</p>
                                        </td>
                                        <td colSpan={1}></td>
                                        <td>
                                          <p>
                                            R{" "}
                                            {calculateRefundOrderDetails(
                                              order
                                            ).toFixed(2)}
                                          </p>

                                          <p>
                                            R {""}
                                            {calculateRefundOrderDetailsVat(
                                              order
                                            ).toFixed(2)}
                                          </p>

                                          <p>
                                            R{" "}
                                            {calculateRefundOrderDetailsTotal(
                                              order
                                            ).toFixed(2)}
                                          </p>
                                        </td>
                                        <td colSpan={1}></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-end mt-4">
                  <ul className="pagination justify-content-center gap-2 align-items-center">
                    <li
                      className={`page-item ${
                        Pagination?.from === 1 ? "disabled" : ""
                      } round_circle_white  pointer `}
                      onClick={() =>
                        Pagination &&
                        Pagination?.page_no > 1 &&
                        handlePagination("prev")
                      }
                    >
                      <i className="bi bi-caret-left"></i>
                    </li>
                    <li
                      className={`page-item    
                 round_circle_green`}
                    >
                      {Pagination?.page_no}
                    </li>

                    {Pagination &&
                      total &&
                      Pagination?.page_no <
                        Math.ceil(total / Pagination?.pageSize) && (
                        <li
                          className={`page-item round_circle_white  pointer`}
                          onClick={() => handlePagination("next")}
                        >
                          <i className="bi bi-caret-right"></i>{" "}
                        </li>
                      )}
                    <li className="page-item">
                      {total ? total : total} records
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerDetails;
