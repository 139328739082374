const reportResponse = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      id: val._source.productCode,
      archSellingPriceIncl: val._source.extraFieldsProduct.archSellingPriceIncl,
      ...val._source,
    };
  });
  let finalData = {
    modifiedData,
    total: data?.total?.value,
  };
  return finalData;
};

const formatDateTime = (isoString: any): any => {
  const date = new Date(isoString);

  return `Last stock synchronization was completed on ${date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })}, at ${date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    // second: '2-digit',
    hour12: true,
  })}.`;
};

export { reportResponse, formatDateTime };
