/* eslint-disable react-hooks/exhaustive-deps */
import { ApexOptions } from "apexcharts";
import axios from "axios";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  getAverageOrderTime,
  getAverageOrderTimeWithOutDate,
  getAverageOrderValue,
  getCombinedProductData,
  getErrorsPerOrderGOR,
  getOrdersData,
  getSalesByProductCategory,
  getSalesStatistics,
  getTopSellingProductByCategory,
  getTotalOrders,
  getTotalSales,
  getTotalSalesOrderReports,
} from "../../Api/Dashboard/mutations";
import Table from "../../Blocks/Table/Table";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import {
  averageOrderTimeOptions,
  averageOrderTimeSeries,
  averageOrderValueChartOptions,
  averageOrderValueChartSeries,
  getLineGraphOptionsSales,
  getVisitor,
  gorOptions,
  gorSeries,
  ordersSalesChartOptions,
  ordersSalesChartSeries,
  productStockDetails,
  salesEachCategoryOptions,
  salesEachCategorySeries,
} from "./data";
import moment from "moment";

interface CombinedProductData {
  SK: string;
  sellQuantity: number;
  itemName: string;
  salesPrice: number;
  costPrice: number;
  netProfit?: number;
  imageURL?: string;
}

const Dashboard = () => {
  const [active, setActive] = useState("daily");
  const { storeId } = useStoreId();
  const [orderChart, setOrderChart] = useState<any>([]);
  const [orderChartDate, setOrderChartDate] = useState<any>([]);
  const [SalesChartDate, setSalesChartDate] = useState<any>([]);
  const [salesChartData, setSalesChartData] = useState<any>([]);
  const [vistor, setVistor] = useState<any>([]);
  const [totalSales, setTotalSales] = useState<any>({});
  const [totalOrders, setTotalOrders] = useState<any>({});
  const [totalAverageOrder, setTotalAverageOrder] = useState<any>({});
  const [errorsPerOrder, setErrorsPerOrder] = useState<any>({});
  const [totalSalesOrderReport, setTotalSalesOrderReport] = useState<any>({});
  const [topSellingProductByCategory, setTopSellingProductByCategory] =
    useState<any[]>([]);
  const [salesByProductCategory, setSalesByProductCategory] = useState<any[]>(
    []
  );
  const [dateError, setDateError] = useState("");
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [averageOrderTimeData, setAverageOrderTimeData] = useState<any>({
    categories: [],
    series: [
      {
        name: "Average Order Time (minutes)",
        data: [],
      },
    ],
  });
  const [groupBy, setGroupBy] = useState<any>("day");
  const [productData, setProductData] = useState<CombinedProductData[]>([]);
  const [takeawayData, setProductTakeAwayData] = useState<
    CombinedProductData[]
  >([]);

  useEffect(() => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const formatDate = (date: Date) =>
      `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;

    setStartDate(formatDate(startOfMonth));
    setEndDate(formatDate(currentDate));
    validateDates(
      formatDate(startOfMonth),
      formatDate(currentDate),
      setDateError,
      fetchSalesByProductCategory
    );
  }, []);

  const validateDates = (
    start: string | number | Date,
    end: string | number | Date,
    setDateError: React.Dispatch<React.SetStateAction<string>>,
    fetchSalesByProductCategory: (startDate: string, endDate: string) => void
  ) => {
    // If both dates are provided, check their validity
    if (start && end) {
      if (new Date(end) < new Date(start)) {
        setDateError(
          "End date must be greater than or equal to the start date."
        );
        return;
      } else {
        setDateError("");
        fetchSalesByProductCategory(start as string, end as string);
      }
    } else {
      setDateError("");
      console.warn(
        "One or both dates missing, calling API to return all data."
      );
      fetchSalesByProductCategory(start as string, end as string);
    }
  };

  useEffect(() => {
    validateDates(
      startDate,
      endDate,
      setDateError,
      fetchSalesByProductCategory
    );
  }, [startDate, endDate]);

  const fetchOrders = async () => {
    try {
      const result = await getOrdersData("order", storeId);
      if (result?.dailyData) {
        const totals = result.dailyData.map((item: any) => item.total);
        setOrderChart(totals);
        const dates = result.dailyData.map(
          (item: any) => item.date.split("T")[0]
        );
        setOrderChartDate(dates);
      } else {
        console.warn("No daily data found in the result.");
        setOrderChart([]);
        setOrderChartDate([]);
      }
    } catch (error) {
      console.error("Failed to fetch order stage data:", error);
    }
  };

  const fetchSalesStatistics = async (
    calendarInterval: string,
    orderId: string | null
  ) => {
    try {
      const result = await getSalesStatistics(
        "order",
        calendarInterval,
        "Asia/Calcutta",
        orderId
      );
      const totals = result.dailyData.map((item) => item.total);
      const dates = result.dailyData.map((item) => item.date.split("T")[0]);
      setSalesChartData(totals);
      setSalesChartDate(dates);
    } catch (error) {
      console.error("Error fetching sales statistics:", error);
    }
  };

  const calculateNetProfit = (data: CombinedProductData[]) => {
    return data.map((item: any) => {
      const salesPrice = parseFloat(item?.salesPrice) || 0.0;
      const costPrice = parseFloat(item?.costPrice) || 0.0;
      const sellQuantity = item?.sellQuantity;
      const profitPerUnit = salesPrice - costPrice;
      const netProfit = profitPerUnit * sellQuantity;
      console.log('netProfit', netProfit)
      return {
        ...item,
        netProfit: netProfit.toFixed(2)
          ? "R " + netProfit.toFixed(2)
          : "R 0.00",
      };
    });
  };

  const GetProductStockDetails = async () => {
    try {
      const data: CombinedProductData[] = await getCombinedProductData(
        "NA",
        storeId
      );
      const updatedData: any = calculateNetProfit(data);
      setProductData(updatedData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const GetProductTakeAwayDetails = async () => {
    try {
      const data: CombinedProductData[] = await getCombinedProductData(
        "NA",
        storeId
      );
      const updatedData: any = calculateNetProfit(data);
      setProductTakeAwayData(updatedData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchTotalSales = async () => {
    try {
      const data = await getTotalSales(storeId);
      const updatedData = {
        metric: "Total Sales",
        currentMonth: `R ${data?.current_month?.total_sales?.value.toFixed(2)}`,
        currentWeek: `R ${data?.current_week?.total_sales?.value.toFixed(2)}`,
        daily: `R ${data?.daily?.total_sales?.value.toFixed(2)}`,
      };
      setTotalSales(updatedData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchTotalOrders = async () => {
    try {
      const data = await getTotalOrders(storeId);
      const updatedData = {
        metric: "Total Orders",
        currentMonth: data?.current_month?.order_count?.value,
        currentWeek: data?.current_week?.order_count?.value,
        daily: data?.daily?.order_count?.value,
      };
      setTotalOrders(updatedData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchAverageOrderValue = async () => {
    const calculateAverage = (total: number, count: number) =>
      count ? `R ${(total / count).toFixed(2)}` : `R ${(0).toFixed(2)}`;
    try {
      const data = await getAverageOrderValue(storeId);
      const updatedData = {
        metric: "Average order value",
        currentMonth: calculateAverage(
          data?.current_month?.total_order_value_sum.value,
          data?.current_month?.order_count?.value
        ),
        currentWeek: calculateAverage(
          data?.current_week?.total_order_value_sum.value,
          data?.current_week?.order_count?.value
        ),
        daily: calculateAverage(
          data?.current_day?.total_order_value_sum.value,
          data?.current_day?.order_count?.value
        ),
      };
      setTotalAverageOrder(updatedData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  function calculateErrorPercentage(
    allOrdersValue: number,
    completedOrdersValue: number
  ) {
    if (allOrdersValue === 0) {
      return "0%"; // No orders, so error percentage is 0%
    }
    const errorPercentage =
      ((allOrdersValue - completedOrdersValue) / allOrdersValue) * 100;
    return `${errorPercentage}%`;
  }

  const fetchErrorsPerOrder = async () => {
    try {
      const data = await getErrorsPerOrderGOR(storeId);
      const errorPercentage = {
        metric: "Errors per order",
        daily: calculateErrorPercentage(
          data.all_orders.current_day.order_value.value,
          data.completed_orders.current_day.order_value.value
        ),
        currentWeek: calculateErrorPercentage(
          data.all_orders.current_week.order_value.value,
          data.completed_orders.current_week.order_value.value
        ),
        currentMonth: calculateErrorPercentage(
          data.all_orders.current_month.order_value.value,
          data.completed_orders.current_month.order_value.value
        ),
      };
      setErrorsPerOrder(errorPercentage);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchTotalSalesOrderReports = async () => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    try {
      const data = await getTotalSalesOrderReports(
        storeId,
        startOfMonth,
        currentDate
      );
      const categories =
        data.length > 0
          ? data.map((bucket: { key_as_string: any }) => bucket.key_as_string)
          : [];
      const totalOrders =
        data.length > 0
          ? data.map(
              (bucket: { total_orders: { value: any } }) =>
                bucket.total_orders.value
            )
          : [];
      const totalSales =
        data.length > 0
          ? data.map((bucket: { total_sales: { value: any } }) =>
              bucket.total_sales.value.toFixed(2)
            )
          : [];
      const updatedData = {
        categories,
        totalOrders,
        totalSales,
        data,
      };
      setTotalSalesOrderReport(updatedData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchTopSellingProductByCategory = async () => {
    try {
      const data = await getTopSellingProductByCategory(storeId);
      setTopSellingProductByCategory(data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchSalesByProductCategory = async (
    startDate: string,
    endDate: string
  ) => {
    try {
      const data = await getSalesByProductCategory(storeId, startDate, endDate);
      setSalesByProductCategory(data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchAverageOrderTimeWithOutDate = async (groupBy: any) => {
    const data = await getAverageOrderTimeWithOutDate(storeId, groupBy); // Change to 'week' or 'month' as needed

    const categories: any = [];
    const seriesData: any = [];

    // Process the aggregation response
    data.forEach((bucket: any) => {
      const startDate = moment(bucket.key_as_string);
      categories.push(
        startDate.format(
          groupBy === "day"
            ? "YYYY-MM-DD"
            : groupBy === "week"
              ? "YYYY-wo"
              : "YYYY-MM"
        )
      );
      const totalTime = bucket?.orders?.buckets?.reduce(
        (acc: number, order: any) => {
          const placedTime = moment(order?.order_placed_time?.value);
          const closedTime = moment(order?.order_closed_time?.value);
          const duration = closedTime.diff(placedTime, "minutes");
          return acc + duration;
        },
        0
      );
      console.log(totalTime, bucket?.orders?.buckets?.length)
      const averageTime = totalTime / bucket?.orders?.buckets?.length; // Average time for the day/week/month
      console.log('averageTime', averageTime);
      
      averageTime > 0 && seriesData.push(averageTime);
    });

    setAverageOrderTimeData({
      categories,
      series: [
        {
          name: "Average Order Time (minutes)",
          data: seriesData && seriesData,
        },
      ],
    });
  };

  useEffect(() => {
    fetchAverageOrderTimeWithOutDate(groupBy);
  }, [groupBy]);

  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setGroupBy(event.target.value);
  };

  useEffect(() => {
    if (storeId) {
      fetchSalesStatistics("1d", storeId);
    }
    setActive("daily");
    GetProductStockDetails();
    GetProductTakeAwayDetails();
    fetchOrders(); // eslint-disable-next-line
    fetchTotalSales();
    fetchTotalOrders();
    fetchAverageOrderValue();
    fetchErrorsPerOrder();
    fetchTotalSalesOrderReports();
    fetchTopSellingProductByCategory();
  }, [storeId]);

  useEffect(() => {
    VisitorApp();
    // eslint-disable-next-line
  }, [storeId]);

  const VisitorApp = () => {
    axios({
      method: "GET",
      url: `https://9gna5ibla5.execute-api.af-south-1.amazonaws.com/default/visitorPantryApp?storeId=${storeId}`,
    })
      .then((res) => {
        setVistor(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTimeFrameClick = (timeFrame: string) => {
    setActive(timeFrame);
    fetchSalesStatistics(
      timeFrame === "daily" ? "1d" : timeFrame === "weekly" ? "1w" : "1M",
      storeId
    );
  };

  const averageOrderValue =
    totalSalesOrderReport?.data?.length > 0
      ? totalSalesOrderReport?.data?.map(
          (bucket: {
            total_orders: { value: number };
            total_sales: { value: number };
          }) => {
            // Avoid division by zero by checking total_orders
            if (bucket.total_orders.value > 0) {
              return (
                bucket.total_sales.value / bucket.total_orders.value
              ).toFixed(2);
            } else {
              return "0.00"; // Set to 0 if no orders
            }
          }
        )
      : [];

  const salesEachCategoryCategories = salesByProductCategory.map(
    (item) => item.category.categoryName
  );
  const salesEachCategorySales = salesByProductCategory.map((item) =>
    item.total_sales.value.toFixed(2)
  );

  const StackedBarChart = () => {
    const categories = [
      ...new Set(
        topSellingProductByCategory.map(
          (item: { category: { categoryName: any } }) =>
            item.category.categoryName
        )
      ),
    ];
    const series = categories.map((category: string | any) => ({
      name: category as string, // Ensures name is treated as a string
      data: topSellingProductByCategory
        .filter(
          (item: { category: { categoryName: any } }) =>
            item.category.categoryName === category
        )
        .map((item: { total_sales: { value: any } }) =>
          item.total_sales.value.toFixed(2)
        ),
    }));
    const productLabels = topSellingProductByCategory.map(
      (item: { key: any }) => item.key
    );

    const optionsStacked: ApexOptions = {
      chart: { type: "bar", stacked: true },
      xaxis: { categories: productLabels },
      title: { text: "Sales by Product Category" },
      tooltip: { y: { formatter: (val) => `R ${val.toFixed(2)}` } },
      dataLabels: {
        enabled: false, // Disable data labels on top of bars
      },
    };

    return (
      <ReactApexChart
        options={optionsStacked}
        series={series}
        type="bar"
        height={400}
      />
    );
  };

  const HorizontalBarChart = () => {
    const topSelling = topSellingProductByCategory
      .map((item: { key: any; total_units_sold: { value: any } }) => ({
        name: item.key,
        sales: item.total_units_sold.value,
      }))
      .sort((a: { sales: number }, b: { sales: number }) => b.sales - a.sales);

    const optionsHorizontal: ApexOptions = {
      chart: { type: "bar" },
      xaxis: { categories: topSelling.map((item: { name: any }) => item.name) },
      title: { text: "Top-Selling Products" },
      plotOptions: { bar: { horizontal: true } },
      tooltip: { y: { formatter: (val) => `${val} units` } },
      dataLabels: {
        enabled: false, // Disable data labels on top of bars
      },
    };

    const seriesHorizontal = [
      {
        name: "Units Sold",
        data: topSelling.map((item: { sales: any }) => item.sales),
      },
    ];

    return (
      <ReactApexChart
        options={optionsHorizontal}
        series={seriesHorizontal}
        type="bar"
        height={400}
      />
    );
  };

  const options: ApexOptions = {
    chart: {
      id: "average-order-time-chart",
    },
    xaxis: {
      categories: averageOrderTimeData.categories,
    },
    yaxis: {
      title: {
        text: "Average Order Time (minutes)",
      },
    },
    title: {
      text: `Average Order Time Trend by ${groupBy.charAt(0).toUpperCase() + groupBy.slice(1)}`,
      align: "center",
    },
  };

  return (
    <div>
      <div className="row mb-4">
        <div className="col-12 pt-4">
          <div className="card">
            <div className="card-body">
              <div className="row ">
                <div className="col-auto">
                  <h4 className="theme_color fw-bolder card-title">
                    {"Sales Statistics"}
                  </h4>
                </div>
                <div className="d-flex col-auto justify-content-end gap-2 headerBtn ms-auto">
                  {["daily", "weekly", "monthly"].map((timeFrame) => (
                    <button
                      key={timeFrame}
                      className={`btn btn-outline-${active === timeFrame ? "success" : "primary"}`}
                      type="button"
                      onClick={() => handleTimeFrameClick(timeFrame)}
                    >
                      {timeFrame.charAt(0).toUpperCase() + timeFrame?.slice(1)}
                    </button>
                  ))}
                </div>
              </div>

              <div className="mt-4">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getLineGraphOptionsSales(
                    SalesChartDate,
                    salesChartData
                  )}
                  containerProps={{ style: { height: "300px" } }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 mb-4">
        <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
          <h4 className="theme_color fw-bolder card-title">
            {"General Overview Report"}
          </h4>
          <div className="col-md-12 col-lg-12 mb-4">
            <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
              <ReactApexChart
                options={gorOptions}
                series={gorSeries([
                  totalSales,
                  totalOrders,
                  totalAverageOrder,
                  errorsPerOrder,
                ])}
                type="line"
                height={350}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 mb-4">
        <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
          <h4 className="theme_color fw-bolder card-title">
            {"Total Sales and Orders Report"}
          </h4>
          <div
            className="d-flex flex-row justify-content-between mb-4"
            style={{ gap: "20px" }}
          >
            <div
              className="border border-grey p-4 rounded white_bg bestsellers-table-card"
              style={{ flex: 1 }}
            >
              {totalSalesOrderReport.categories?.length ? (
                <ReactApexChart
                  options={ordersSalesChartOptions(totalSalesOrderReport)}
                  series={ordersSalesChartSeries(totalSalesOrderReport)}
                  type="bar"
                  height={350}
                />
              ) : null}
            </div>
            <div
              className="border border-grey p-4 rounded white_bg bestsellers-table-card"
              style={{ flex: 1 }}
            >
              <ReactApexChart
                options={averageOrderValueChartOptions(totalSalesOrderReport)}
                series={averageOrderValueChartSeries(averageOrderValue)}
                type="line"
                height={350}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 mb-4">
        <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
          <h4 className="theme_color fw-bolder card-title">
            {"Top Selling Products by Category"}
          </h4>
          <div
            className="d-flex flex-row justify-content-between mb-4"
            style={{ gap: "20px" }}
          >
            <div
              className="border border-grey p-4 rounded white_bg bestsellers-table-card"
              style={{ flex: 1 }}
            >
              {topSellingProductByCategory?.length ? <StackedBarChart /> : null}
            </div>
            <div
              className="border border-grey p-4 rounded white_bg bestsellers-table-card"
              style={{ flex: 1 }}
            >
              {topSellingProductByCategory?.length ? (
                <HorizontalBarChart />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 mb-4">
        <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
          <h4 className="theme_color fw-bolder card-title">
            {"Total sales for each category"}
          </h4>
          <div className="col-md-12 col-lg-12 mb-4">
            <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
              <ReactApexChart
                options={salesEachCategoryOptions(salesEachCategoryCategories)}
                series={salesEachCategorySeries(salesEachCategorySales)}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 mb-4">
        <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
          <h4 className="theme_color fw-bolder card-title">
            {"Average Order Time Report"}
          </h4>
          <label htmlFor="time-period-select">Select Time Period:</label>
          <select
            id="time-period-select"
            value={groupBy}
            onChange={handlePeriodChange}
            style={{ marginLeft: "10px" }}
          >
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
          </select>
          <div className="col-md-12 col-lg-12 mb-4">
            <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
              <ReactApexChart
                options={options}
                series={averageOrderTimeData.series}
                type="line"
                height={350}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 mb-4">
        <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
          <h4 className="theme_color fw-bolder card-title">{"Bestsellers"}</h4>
          <div className="row mt-2">
            <div className="col-md-6 col-lg-6 mb-4">
              <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
                <h4 className="theme_color fw-bolder card-title">
                  {"AIL Products"}
                </h4>
                <Table
                  columns={productStockDetails}
                  data={productData}
                  paginationShow={false}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 mb-4">
              <div className="border border-grey p-4 rounded white_bg bestsellers-table-card">
                <h4 className="theme_color fw-bolder card-title">
                  {"Take Away"}
                </h4>
                <Table
                  columns={productStockDetails}
                  data={takeawayData}
                  paginationShow={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div> 

      <div className="row">
        <div className="col-md-6 mb-4 d-flex">
          <div className="p-4 pe-2 p-md-4   white_bg flex-grow-1">
            <h5 className="theme_color fw-bold">{"Orders"} </h5>
            <span className="theme_color f-small">Last 7 days</span>
            <div className="mt-4">
              <HighchartsReact
                highcharts={Highcharts}
                options={getLineGraphOptionsSales(orderChartDate, orderChart)}
                containerProps={{ style: { height: "300px" } }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4 d-flex">
          <div className="p-4 pe-2 p-md-4   white_bg flex-grow-1">
            <h5 className="theme_color fw-bold">{"Visitor"} </h5>
            <span className="theme_color f-small">&nbsp;</span>
            <span className="theme_color f-small">Last 7 days</span>
            <div className="mt-4">
              <HighchartsReact
                highcharts={Highcharts}
                options={getVisitor(vistor)}
                containerProps={{ style: { height: "300px" } }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
