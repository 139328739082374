import axios from "axios";
import { getAuthToken } from "../utils";

interface SearchData {
  fields: string;
  value: string | { from: string; to: string };
}

interface Pagination {
  from: number;
  size: number;
}

interface Pagination {
  from: number;
  size: number;
}

export const serchReports = async (
  indexName: string,
  data: SearchData[],
  pagination: Pagination,
  storeId: string | null,
): Promise<any> => {
  if (!Array.isArray(data)) {
    throw new Error(
      "Data must be an array of objects with fields and values properties",
    );
  }
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  const mustClauses = data
    .filter((item) => {
      if (typeof item.value === "object") {
        return item.value.from !== "" || item.value.to !== "";
      }
      return item.value.trim() !== "";
    })
    .map((item) => {
      if (typeof item.value === "object") {
        const rangeQuery: any = {};
        if (item.value.from !== "") {
          rangeQuery["gte"] = item.value.from.trim();
        }
        if (item.value.to !== "") {
          rangeQuery["lte"] = item.value.to.trim();
        }
        return {
          range: {
            [item.fields]: rangeQuery,
          },
        };
      } else if (
        item.fields === "availableQuantity" ||
        item.fields === "sellQuantity"
      ) {
        // Use a term query for exact match for numeric fields
        const termQuery: any = {};
        if (item.value.trim()) {
          termQuery["value"] = item.value.trim(); // Exact match of the trimmed value
        }
        return {
          term: {
            [item.fields]: termQuery,
          },
        };
      } else {
        return {
          query_string: {
            fields: [item.fields],
            query: `*${item.value.trim()}*`,
          },
        };
      }
    });
  const queryBody = {
    from: pagination.from,
    size: pagination.size,
    // sort: [
    //   {
    //     createdDate: {
    //       order: "desc",
    //     },
    //   },
    // ],
    query: {
      bool: {
        must: mustClauses.length > 0 ? mustClauses : [{ match_all: {} }],
        filter: [{ match: { storeId: storeId } }, { match: { 'extraFieldsProduct.archTradeOnline': true }} , {match: { 'extraFieldsProduct.archPOSItem': true }}],
      },
    },
  };
  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });
    return response.data?.hits;
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};

export const getAllExportReports = async (
  query: string,
  indexName: string,
  storeId: string | null,
) => {
  let index = process.env.REACT_APP_STACK
    ? `${indexName}${process.env.REACT_APP_STACK}`
    : indexName;
  const searchUrl = `${process.env.REACT_APP_OPEN_SEARCH_BASE_URL}/${index}/_search`;
  let val = storeId ? { term: { storeId: storeId } } : { match_all: {} };

  const queryBody = {
    size: 10000,
    query: {
      bool: {
        filter: [val],
      },
    },
  };

  try {
    const response = await axios.post(searchUrl, queryBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthToken(),
      },
    });

    return response.data?.hits;
  } catch (error) {
    console.error("Error performing search query:", error);
    throw error;
  }
};
