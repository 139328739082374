import { FormConfigItem } from "../../Blocks/Form/interface";

const columns_name = [
  {
    label: "",
    sub_header: "",
    type: "checkbox",
  },
  {
    field: "description",
    label: "Name",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Name",
    },
  },
  {
    field: "sku",
    label: "Product Code",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Product Code",
    },
  },
  {
    field: "availableQuantity",
    label: "Available Quantity",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Available Quantity",
    },
  },
  {
    field: "sellQuantity",
    label: "Sold Quantity",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Sold Quantity",
    },
  },
  {
    field: ["archSellingPriceIncl"],
    label: "Price",
    type: "text",
    sub_header: {
      inputtype: "input",
      placeholder: "Price",
    },
  },
];

const data = [
  {
    id: "1",
    code: "Item",
    store_location: "Store Location 100",
    status: "true",
  },
  {
    id: "2",
    code: "Item",
    store_location: "Store Location 100",
    status: "true",
  },
  {
    id: "3",
    code: "Item",
    store_location: "Store Location 100",
    status: "true",
  },
];

const formconfig: FormConfigItem[] = [
  {
    heading: "General",
    sub_header: [
      {
        label: "Store Code",
        fieldtype: "input",
        type: "text",
        size: "col-md-6",
        name: "storeCode",
        required: true,
        readonly: true,
      },
      {
        label: "Store Title",
        fieldtype: "input",
        type: "text",
        size: "col-md-6",
        name: "name",
        required: true,
        min: 1,
        max: 50,
      },
      {
        label: "Reward Amount",
        fieldtype: "input",
        type: "text",
        size: "col-md-6",
        name: "rewardAmount",
        required: true,
        min: 1,
        max: 50,
      },
      {
        label: "Reward Rate(pts)",
        fieldtype: "input",
        type: "text",
        size: "col-md-6",
        name: "rewardRate",
        required: true,
        min: 1,
        max: 50,
      },
      {
        label: "Store Description",
        fieldtype: "textarea",
        type: "textarea",
        size: "col-12",
        name: "description",
        required: false,
      },
      // {
      //   label: "Store Manager",
      //   fieldtype: "input",
      //   type: "text",
      //   size: "col-12",
      //   name: "storeManager",
      //   required: true,
      //   min: 3,
      //   max: 50,
      // },
      {
        label: "Store Location",
        fieldtype: "location",
        type: "text",
        size: "col-12",
        name: "location",
        required: true,
      },

      {
        label: "Latitude",
        fieldtype: "input",
        type: "text",
        size: "col-6",
        name: "lat",
        required: true,
        readonly: true,
      },
      {
        label: "Longitude",
        fieldtype: "input",
        type: "text",
        size: "col-6",
        name: "long",
        required: true,
        readonly: true,
      },
      {
        label: "File",
        fieldtype: "file",
        type: "file",
        size: "col-6",
        name: "imageURL",
        required: false,
      },
      {
        label: "Status",
        fieldtype: "status",
        type: "text",
        size: "col-6",
        name: "itemStatus",
        required: false,
      },
    ],
  },
];

const inputfield: { [key: string]: string | boolean } = {
  PK: "",
  SK: "",
  name: "",
  storeCode: "",
  description: "",
  location: "",
  lat: "",
  long: "",
  storeManager: "",
};

const gorcolumns_name = [
  {
    label: "Metric",
    field: "metric",
    type: "text",
  },
  {
    label: "Daily",
    field: "daily",
    type: "text",
  },
  {
    label: "Current Week",
    field: "currentWeek",
    type: "text",
  },
  {
    label: "Current Month",
    field: "currentMonth",
    type: "text",
  },
];

const table_sales_report_columns_name = [
  {
    label: "Date",
    field: "date",
    type: "text",
  },
  {
    label: "Total Sales (R)",
    field: "totalSales",
    type: "text",
  },
  {
    label: "Total Orders",
    field: "totalOrders",
    type: "text",
  },
  {
    label: "Average Order Value (R)",
    field: "averageOrderValue",
    type: "text",
  },
];

const sales_product_category_columns_name = [
  {
    label: "Product Category",
    field: "productCategory",
    type: "text",
  },
  {
    label: "Product Name",
    field: "productName",
    type: "text",
  },
  {
    label: "Total Sales (R)",
    field: "totalSales",
    type: "text",
  },
  {
    label: "Total Units Sold",
    field: "totalUnitsSold",
    type: "text",
  },
];

const top_selling_products_by_category_columns_name = [
  {
    label: "Product Category",
    field: "productCategory",
    type: "text",
  },
  {
    label: "Total Sales (R)",
    field: "totalSales",
    type: "text",
  },
  {
    label: "Percentage of Sales",
    field: "percentageofSales",
    type: "text",
  },
];

const average_order_time_columns_name = [
  {
    label: "Order ID",
    field: "orderId",
    type: "text",
  },
  {
    label: "Order Placed Time",
    field: "orderPlacedTime",
    type: "text",
  },
  {
    label: "Order Closed Time",
    field: "orderClosedTime",
    type: "text",
  },
  {
    label: "Time Taken (HH:MM)",
    field: "timeTaken",
    type: "text",
  },
];

const errors_per_order_columns_name = [
  {
    label: "Date",
    field: "date",
    type: "text",
  },
  {
    label: "Total Orders",
    field: "totalOrders",
    type: "text",
  },
  {
    label: "Orders with Errors",
    field: "ordersWithErrors",
    type: "text",
  },
  {
    label: "Error Rate (%)",
    field: "errorRate",
    type: "text",
  },
];

const hourly_sales_trends_report_columns_name = [
  {
    label: "Hour",
    field: "hour",
    type: "text",
  },
  {
    label: "Total Sales (R)",
    field: "totalSales",
    type: "text",
  },
  {
    label: "Total Orders",
    field: "totalOrders",
    type: "text",
  },
];

const refund_cancellation_report_columns_name = [
  {
    label: "Date",
    field: "date",
    type: "text",
  },
  {
    label: "Total Refunds (R)",
    field: "totalRefunds",
    type: "text",
  },
  {
    label: "Total Cancellations",
    field: "totalCancellation",
    type: "text",
  },
  {
    label: "Refund %",
    field: "refund",
    type: "text",
  },
  {
    label: "Top Refund Reason",
    field: "refundReason",
    type: "text",
  },
];

const new_vs_returning_customers_columns_name = [
  {
    label: "Date",
    field: "date",
    type: "text",
  },
  {
    label: "New Customers",
    field: "newCustomers",
    type: "text",
  },
  {
    label: "Returning Customers",
    field: "returningCustomers",
    type: "text",
  },
  {
    label: "Total Customers",
    field: "totalCustomers",
    type: "text",
  },
];

const customer_purchase_frequency_columns_name = [
  {
    label: "Customer ID",
    field: "customerId",
    type: "text",
  },
  {
    label: "Purchases",
    field: "purchases",
    type: "text",
  },
];

const abandoned_cart_summary_columns_name = [
  {
    label: "Date",
    field: "date",
    type: "text",
  },
  {
    label: "Total Carts",
    field: "totalCarts",
    type: "text",
  },
  {
    label: "Abandoned Carts",
    field: "abandonedCarts",
    type: "text",
  },
  {
    label: "Abandonment Rate (%)",
    field: "abandonementRate",
    type: "text",
  },
];

const out_of_stock_products_columns_name = [
  {
    label: "#",
    field: "no",
    type: "text",
  },
  {
    label: "Product Code",
    field: "productName",
    type: "text",
  },
  {
    label: "Available Quantity",
    field: "availableQuantity",
    type: "text",
  },
  {
    label: "Sold Quantity",
    field: "sellQuantity",
    type: "text",
  },
];

const driver_report_columns_name = [
  {
    label: "Date",
    field: "date",
    type: "text",
  },
  {
    label: "Name",
    field: "driverName",
    type: "text",
  },
  {
    label: "OrderID",
    field: "orderId",
    type: "text",
  },
  {
    label: "Tip Amount",
    field: "amount",
    type: "text",
  },
  {
    label: "Distance",
    field: "distance",
    type: "text",
  },
  {
    label: "Duration",
    field: "duration",
    type: "text"
  }
];

export {
  columns_name,
  data,
  formconfig,
  inputfield,
  gorcolumns_name,
  table_sales_report_columns_name,
  sales_product_category_columns_name,
  top_selling_products_by_category_columns_name,
  average_order_time_columns_name,
  errors_per_order_columns_name,
  hourly_sales_trends_report_columns_name,
  refund_cancellation_report_columns_name,
  new_vs_returning_customers_columns_name,
  customer_purchase_frequency_columns_name,
  abandoned_cart_summary_columns_name,
  out_of_stock_products_columns_name,
  driver_report_columns_name,
};
