const ProductResponse = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      id: val._source.productCode,
      value: val._source.productCode,
      label: val._source.itemName,
      salesPrice: val?._source?.extraFieldsProduct?.salesPrice,
      costPrice: val?._source?.extraFieldsProduct?.costPrice,
      qty: val?._source?.extraFieldsProduct?.archAvailableUnitQuantity,
      ...val._source,
    };
  });

  const sortedData = modifiedData.sort((a: any, b: any) => {
    if (a?.priority !== undefined && b?.priority !== undefined) {
      return a?.priority - b?.priority; // Sort by 'priority' in ascending order
    }
    if (a?.updatedDate && b?.updatedDate) {
      return (
        new Date(b?.updatedDate).getTime() - new Date(a?.updatedDate).getTime()
      );
    }
    return 0;
  });

  let finalData = {
    modifiedData: sortedData,
    total: data?.total?.value,
  };
  return finalData;
};

export { ProductResponse };

const ProductResponseMATCHSKU = (data: any) => {
  const modifiedData = data?.map((val: any) => {
    return {
      id: val.productCode,
      value: val.productCode,
      label: val.itemName,
      ...val,
      ...val.extraFieldsProduct,
    };
  });
  let finalData = {
    modifiedData,
    total: data?.total?.value,
  };
  return finalData;
};

export { ProductResponseMATCHSKU };

export const List = [
  {
    id: "MEAL",
    value: "MEAL",
    label: "MEAL",
    data: "MEAL",
  },
  {
    id: "STORE",
    value: "STORE",
    label: "STORE",
    data: "STORE",
  },
  {
    id: "EXTRATAKEAWAY",
    value: "EXTRATAKEAWAY",
    label: "EXTRATAKEAWAY",
    data: "EXTRATAKEAWAY",
  },
];
