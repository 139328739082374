import { capitalizeFirstLetter } from "../../Common Functions/Function";

export const processOrderResponse = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    const refundItems = val?._source?.refundOrderDetails || [];
    const orderItems = val?._source?.orderDetails || [];

    let total = val._source?.total || 0;
    val._source.paymentStatus = capitalizeFirstLetter(val._source?.paymentStatus?.toLowerCase());
    val._source.status = capitalizeFirstLetter(val._source?.status);
    
    // const calculateTotal = (items: any[]) => {
    //   return items.reduce((acc, item) => {
    //     let itemTotal = (item.salesPrice || 0) * (item.quantity || 0);

    //     // Include extraFieldsProduct items in the calculation
    //     if (Array.isArray(item.extraFieldsProduct)) {
    //       itemTotal += item.extraFieldsProduct.reduce(
    //         (extraAcc:any, extraItem:any) => extraAcc + (extraItem.salePrice || 0) * (extraItem.quantity || 0),
    //         0
    //       );
    //     }

    //     return acc + itemTotal;
    //   }, 0);
    // };

    const calculateTotal = (items: any[]): number => {
      const getItemTotal = (item: any): number => {
        let itemTotal = (item.salesPrice || 0) * (item.quantity || 0);
    
        // If the item has sub-products in extraFieldsProduct, calculate their total recursively
        if (Array.isArray(item.extraFieldsProduct)) {
          itemTotal += item.extraFieldsProduct.reduce(
            (extraAcc: number, extraItem: any) => extraAcc + getItemTotal(extraItem),
            0
          );
        }
    
        // If the item has sub-products in subProduct, calculate their total recursively
        if (Array.isArray(item.subProduct)) {
          itemTotal += item.subProduct.reduce(
            (subAcc: number, subItem: any) => subAcc + getItemTotal(subItem),
            0
          );
        }
    
        return itemTotal;
      };
    
      return items.reduce((acc, item) => acc + getItemTotal(item), 0);
    };

    if (refundItems.length > 0 || orderItems.length > 0) {
      total = calculateTotal([...refundItems, ...orderItems]);
    }
    const deliveryCharge = Number(val?._source?.deliveryCharge) || 0;
    const tip = Number(val?._source?.tip) || 0;
    
    total += deliveryCharge;
    total += tip;
    console.log('logs', total);
    return {
      id: val._source.productCode,
      ...val._source,
      total: total?.toFixed(2),
    };
  });

  let finalData = {
    modifiedData,
    total: data?.total?.value
  };
  console.log(finalData);
  return finalData;
};

export const processCustomerData = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      id: val._source.userId,
      ...val._source,
    };
  });

  const finalData = {
    modifiedData,
    total: data?.total?.value,
  };

  return finalData;
};

export const processDriverData = (data: any) => {
  const modifiedData = data?.hits?.map((val: any) => {
    return {
      id: val._source.userId,
      ...val._source,
      value: val._source.userId,
      label: val._source.fullName,
    };
  });
  let finalData = {
    modifiedData,
    total: data?.total?.value,
  };
  return finalData;
};
