export const CREATE_STORE = `
mutation MyMutation($input: StoreInput!) {
  createStore(input: $input) 
}

`;

export const DELETE_STORE = `
mutation MyMutation ($PK: String!, $SK: String!){
  deleteStore(PK: $PK, SK: $SK)
}
`;

export const UPDATE_STORE = `
mutation MyMutation($PK: String!, $SK: String!, $input: StoreInput!) {
  updateStore(PK: $PK, SK: $SK, input: $input)
}
`;

export const UPDATE_STROE_STORE = `
mutation MyMutation($input: OpeningHours!) {
  openingHours(input: $input)
}
`;