import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Routers } from "../../Routes/Routes";
import { useAlert } from "../ContextAPI/ContextApi";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { useCallback, useEffect, useState } from "react";
import LoadingOverlay from "../../Effects/LoadingOverlay";
import { searchWithPKSK } from "../../Api/Search/mutation";
import { membershipResponse } from "./utils";
import { toast } from "react-toastify";
import FormEffect from "../../Effects/FormEffect";
import { updateMembership } from "../../Api/Membership/mutation";
import Toast from "../Toast/Toast";

interface FormValues {
  name: string;
  rewardsPointsPerRand: number;
  rewardsRandsPerPoint: number;
  rewardsPointsExpirationDays: number;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  rewardsPointsPerRand: Yup.number()
    .required("Points per Rand are required")
    .positive("Must be a positive number")
    .integer("Must be an integer"),
  rewardsRandsPerPoint: Yup.number()
    .required("Rands per Point are required")
    .positive("Must be a positive number"),
  rewardsPointsExpirationDays: Yup.number()
    .required("Expiration days are required")
    .positive("Must be a positive number")
    .integer("Must be an integer"),
});

const generateUniqueName = () => {
  const now = new Date();
  const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Month with leading zero
  const day = now.getDate().toString().padStart(2, "0"); // Day with leading zero
  const hour = now.getHours().toString().padStart(2, "0"); // Hour with leading zero
  const minute = now.getMinutes().toString().padStart(2, "0"); // Minute with leading zero
  const second = now.getSeconds().toString().padStart(2, "0"); // Second with leading zero
  const random = Math.random().toString(36).substring(2, 6).toUpperCase(); // Random 4-character string

  // Format: YYMMDDHHMMSS + Random 4 characters
  return `${year}${month}${day}${hour}${minute}${second}${random}`;
};

const UpdateMemberShip = () => {
  const { alert, message, setAlert, setMessage, setErrorType } = useAlert();
  const { storeId } = useStoreId();
  const [isLoading, setIsLoading] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const { PK, SK } = useParams();
  const [store, setStore] = useState<any>({});
  const navigate = useNavigate();

  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      rewardsPointsPerRand: 0,
      rewardsRandsPerPoint: 0,
      rewardsPointsExpirationDays: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setUpdateLoader(true); // Start the loader at the beginning
      try {
        const newData: any = {
          PK: store?.PK,
          SK: store?.SK,
          id: store?.id,
          entityName: store?.entityName,
        };
        const mergeData = {
          ...values,
          ...newData,
        };

        await updateMembership(mergeData, PK, SK)
          .then((res: any) => {
            const result = JSON.parse(res?.data?.updateStore);
            const errorMessage = JSON.parse(result.body).message;
            if (result?.statusCode === 200) {
              navigate(Routers?.memberShip); // Navigate to store page
              setAlert(true);
              setErrorType("success");
              setMessage("Membership updated successfully");
            } else {
              setErrorType("error");
              setAlert(true);
              setMessage(errorMessage);
            }
          })
          .catch((err: any) => {
            console.log(err);
            setErrorType("error");
            setAlert(true);
            setMessage(err?.errors?.[0]?.message || "An error occurred");
            setUpdateLoader(false); // Stop the loader
          });
      } catch (err: any) {
      } finally {
        setUpdateLoader(false); // Stop the loader
      }
    },
  });

  const handleSubmit = () => {
    if (!storeId) {
      setAlert(true);
      setErrorType("error");
      setMessage("First select a store");
      return;
    }
    if (formik.isValid && storeId) {
      formik.handleSubmit();
    } else {
      formik.submitForm();
    }
  };

  const getStore = useCallback(() => {
    setIsLoading(true);
    searchWithPKSK("store-membership", PK, SK)
      .then((res) => {
        if (res) {
          const result = membershipResponse(res);
          setStore(result?.modifiedData?.[0] || {});
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to fetch store data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [PK, SK]);

  useEffect(() => {
    getStore();
  }, [getStore]);

  useEffect(() => {
    if (store) {
      formik.setValues({
        ...formik.values,
        name: generateUniqueName(),
        rewardsPointsExpirationDays: store?.rewardsPointsExpirationDays,
        rewardsPointsPerRand: store?.rewardsPointsPerRand,
        rewardsRandsPerPoint: store?.rewardsRandsPerPoint,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {isLoading ? (
        <FormEffect />
      ) : (
        <>
          <LoadingOverlay isVisible={updateLoader} message="Please wait..." />
          {alert && message && <Toast type={"error"} message={message} />}
          <div className="form-top-sticky d-flex align-items-center justify-content-between gap-2">
            <h6 className="theme_color align-self-center h4 fw-bold mb-3">
              <Link
                className="text-decoration-none theme_color"
                to={Routers?.memberShip}
              >
                <i className="bi bi-chevron-left me-3"></i>
              </Link>
              {"Update New Membership"}
            </h6>
            <div className="row justify-content-between mb-4">
              <div className="col-auto d-flex align-items-center"></div>
              <div className="col-auto d-flex gap-2 justify-content-end">
                <Link className="text-decoration-none" to={Routers.memberShip}>
                  <button className="btn btn-outline-danger" type="button">
                    Cancel
                  </button>
                </Link>
                <button
                  className="btn btn-outline-success"
                  type="button"
                  onClick={handleSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          {/* 
          <div className="form-group col-12 mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-danger">{formik.errors.name}</div>
            ) : null}
          </div> */}

          <div className="form-group col-12 mb-3">
            <label htmlFor="rewardsPointsPerRand" className="form-label">
              Rewards Points Per Rand
            </label>
            <input
              type="number"
              className="form-control"
              id="rewardsPointsPerRand"
              name="rewardsPointsPerRand"
              onChange={formik.handleChange}
              value={formik.values.rewardsPointsPerRand}
            />
            <small className="form-text text-muted">
              <i className="bi bi-info-circle me-1"></i>
              {`Number of points earned per ZAR ${formik.values.rewardsPointsPerRand} spent`}
            </small>
            {formik.touched.rewardsPointsPerRand &&
            formik.errors.rewardsPointsPerRand ? (
              <div className="text-danger">
                {formik.errors.rewardsPointsPerRand}
              </div>
            ) : null}
          </div>

          <div className="form-group col-12 mb-3">
            <label htmlFor="rewardsRandsPerPoint" className="form-label">
              Rands Per Point
            </label>
            <input
              type="number"
              id="rewardsRandsPerPoint"
              className="form-control"
              name="rewardsRandsPerPoint"
              onChange={formik.handleChange}
              value={formik.values.rewardsRandsPerPoint}
            />
            <small className="form-text text-muted">
              <i className="bi bi-info-circle me-1"></i>
              {`ZAR amount required to earn ${formik.values.rewardsRandsPerPoint} point`}
            </small>
            {formik.touched.rewardsRandsPerPoint &&
            formik.errors.rewardsRandsPerPoint ? (
              <div className="text-danger">
                {formik.errors.rewardsRandsPerPoint}
              </div>
            ) : null}
          </div>

          <div className="form-group col-12 mb-3">
            <label htmlFor="rewardsPointsExpirationDays" className="form-label">
              Points Expiration Days
            </label>
            <input
              type="number"
              className="form-control"
              id="rewardsPointsExpirationDays"
              name="rewardsPointsExpirationDays"
              onChange={formik.handleChange}
              value={formik.values.rewardsPointsExpirationDays}
            />
            {formik.touched.rewardsPointsExpirationDays &&
            formik.errors.rewardsPointsExpirationDays ? (
              <div className="text-danger">
                {formik.errors.rewardsPointsExpirationDays}
              </div>
            ) : null}
          </div>
        </>
      )}
    </form>
  );
};

export default UpdateMemberShip;
