import debounce from "lodash.debounce";
import { useState, useCallback, useEffect, ChangeEvent } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  getStatus,
  updateFilterAndPagination,
  handlePaginations,
  handlePageSizes,
} from "../../Common Functions/Function";
import { Routers } from "../../Routes/Routes";
import { useAlert } from "../ContextAPI/ContextApi";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import swal from "sweetalert";
import { columns_name } from "./data";
import Table from "../../Blocks/Table/Table";
import { membershipResponse } from "./utils";
import { updateMembership } from "../../Api/Membership/mutation";
import {
  getAllMeberShip,
  searchMembership,
} from "../../Api/Search/MemberShip/Membership";
import Toast from "../Toast/Toast";

type SearchData = {
  fields: string;
  value: string;
};

const Membership = () => {
  const { alert, message, errorType, setAlert } = useAlert();
  const navigate = useNavigate();
  const [parentItems, setParentItems] = useState([]);
  const [, setMeberShip] = useState([]);
  const [enable, setEnable] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [filtervalue, setFilterValue] = useState<SearchData[]>([]);
  const [total, setTotal] = useState();
  const { storeId } = useStoreId();
  const [Pagination, setPagination] = useState({
    from: 0,
    size: 50,
    page_no: 1,
    pageSize: 50, //per_page_records
    total_pages: 0,
  });

  // eslint-disable-next-line
  const getMembershipList = useCallback(
    debounce(async (filtervalue: SearchData[], Pagination: any) => {
      setIsLoading(true);
      await searchMembership("store-membership", filtervalue, Pagination)
        .then(async (res: any) => {
          const result = membershipResponse(res);
          setParentItems(result?.modifiedData);
          setTotal(result?.total);
          setIsLoading(false);
        })
        .catch((err: any) => {
          console.error("Error fetching memberships:", err);
          setIsLoading(false);
        });
    }, 3000),
    [storeId],
  );

  useEffect(() => {
    getMembershipList(filtervalue, Pagination);
  }, [getMembershipList, filtervalue, Pagination]);

  const handleDelete = async (data: any) => {
    const willDelete = await swal({
      text: `Are you sure you want to ${getStatus(data?.isDeleted)} Membership ${data?.name}`,
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: {
          text: getStatus(data?.isDeleted),
          value: true,
        },
      },
    });

    if (willDelete) {
      const payload = {
        ...data,
        isDeleted:
          getStatus(data?.isDeleted) === "Disable"
            ? `${storeId}-true`
            : `${storeId}-false`,
      };
      delete payload.label;
      delete payload.value;

      await updateMembership(data?.PK, data?.SK, payload)
        .then((res: any) => {
          const result = JSON.parse(res?.data?.updateMembership);
          const errorMessage = JSON.parse(result.body).message;
          if (result?.statusCode === 200) {
            swal(
              getStatus(data?.isDeleted),
              `Membership has been ${getStatus(data?.isDeleted)} successfully`,
              "success",
            );
            getMembershipList(filtervalue, Pagination);
          } else {
            swal(errorMessage);
          }
        })
        .catch((err: any) => {
          swal(
            `Failed to ${getStatus(data?.isDeleted)} Membership. Please try again later.`,
          );
        });
    }
  };

  const membershipEdit = (data: { PK: string; SK: string }) => {
    navigate(
      `${Routers.updateMemberships
        .replace(":PK", encodeURIComponent(data.PK))
        .replace(":SK", encodeURIComponent(data?.SK))}`,
    );
  };

  const filterdata = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { name, value } = e.target;
      updateFilterAndPagination(
        filtervalue,
        setFilterValue,
        setPagination,
        name,
        value,
      );
    }
  };

  const handlePagination = useCallback((direction: "next" | "prev") => {
    handlePaginations(setPagination, direction);
  }, []);

  const handlePageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { value } = e.target;
      handlePageSizes(setPagination, value);
    }
  };

  const getAllMeberShips = () => {
    getAllMeberShip("", "store", storeId)
      .then((res) => {
        const result = membershipResponse(res);
        setMeberShip(result?.modifiedData || []);
        if (result?.total === 0) {
          setEnable(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      getAllMeberShips();
    }, 3000);

    return () => clearTimeout(delay); // eslint-disable-next-line
  }, []);

  return (
    <>
      {alert && message && (
        <Toast type={errorType ? errorType : "success"} message={message} />
      )}
      <div className="row mb-4 mt-4 justify-content-between">
        <div className="col-auto">
          <h3 className="theme_color fw-bolder m-0">Membership</h3>
        </div>

        {enable === true && (
          <div className="col-auto ms-auto" onClick={() => setAlert(false)}>
            <Link className="text-decoration-none" to={Routers?.NewMemberShip}>
              <button className="btn btn-outline-success" type="button">
                New Membership
              </button>
            </Link>
          </div>
        )}
      </div>
      <div className="border p-4 border-grey rounded white_bg">
        <Table
          columns={columns_name}
          data={parentItems || []}
          paginationShow={true}
          handleDelete={handleDelete}
          handleEdit={membershipEdit}
          isLoading={isLoading}
          filterdata={filterdata}
          handlePagination={handlePagination}
          Pagination={Pagination}
          handlePageSize={handlePageSize}
          total={total}
        />
      </div>
    </>
  );
};

export default Membership;
