import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addOpeningHours } from "../../Api/Store/mutation";
import { useStoreId } from "../ContextAPI/StoreIdContext";
import { searchWithPKSK } from "../../Api/Search/mutation";
import { toast } from "react-toastify";
import LoadingOverlay from "../../Effects/LoadingOverlay";
import Toast from "../Toast/Toast";
import { useAlert } from "../ContextAPI/ContextApi";

import moment from "moment-timezone";


const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

type TimeSlot = { start: Date | null; end: Date | null };

interface BusinessHoursState {
  [key: string]: { open: boolean; slots: TimeSlot[] };
}


const BusinessHours: React.FC = () => {

    const { storeId } = useStoreId();
    const [isLoading, setIsLoading] = useState(false);
    const { alert, message, errorType, setAlert, setMessage, setErrorType } = useAlert();
    

    let index = process.env.REACT_APP_STACK
    ? `store-setting${process.env.REACT_APP_STACK}`
    : "store-setting";

    // const defaultStartTime = new Date();
    // defaultStartTime.setHours(9, 0, 0, 0); // 9:00 AM

    // const defaultEndTime = new Date();
    // defaultEndTime.setHours(17, 0, 0, 0); // 5:00 PM

    // Set default business hours in Africa/Johannesburg timezone
    const defaultStartTime = moment.tz("09:00", "HH:mm", "Africa/Johannesburg").toDate();
    const defaultEndTime = moment.tz("17:00", "HH:mm", "Africa/Johannesburg").toDate();

    const [businessHours, setBusinessHours] = useState<BusinessHoursState>(
        daysOfWeek.reduce((acc, day) => {
        acc[day] = {
            open: true,
            slots: [{ start: defaultStartTime, end: defaultEndTime }], // Default 9 AM - 5 PM
        };
        return acc;
        }, {} as BusinessHoursState)
    );

    const toggleOpenStatus = (day: string) => {
        setBusinessHours((prev) => ({
        ...prev,
        [day]: { ...prev[day], open: !prev[day].open },
        }));
    };

    const updateTimeSlot = (day: string, index: number, field: "start" | "end", value: Date | null) => {
        const convertedTime = moment(value).tz("Africa/Johannesburg").toDate();
        const newSlots = [...businessHours[day].slots];
        newSlots[index] = field === "start" ? { ...newSlots[index], start: convertedTime } : { ...newSlots[index], end: convertedTime };
        setBusinessHours((prev) => ({
        ...prev,
        [day]: { ...prev[day], slots: newSlots },
        }));
    };

    const addTimeSlot = (day: string) => {
        setBusinessHours((prev) => ({
        ...prev,
        [day]: { ...prev[day], slots: [...prev[day].slots, { start: null, end: null }] },
        }));
    };

    const removeTimeSlot = (day: string, index: number) => {
        const newSlots = businessHours[day].slots.filter((_, i) => i !== index);
        setBusinessHours((prev) => ({
        ...prev,
        [day]: { ...prev[day], slots: newSlots },
        }));
    };

    const newData: any = {
        PK: `STORE-SETTING`,
        SK: `ACTIVE#${storeId}`,
        id: storeId,
        storeId:storeId,
        entityName: index,
        isDeleted: `false`,
        businessHours: JSON.stringify(businessHours)
    };


    const saveBusinessHours = async () => {
        setIsLoading(true);
        console.log('newData', newData)
        // return;
        try {
            const res = await addOpeningHours(newData);
            const result = JSON.parse(res?.data?.openingHours);
            console.log('result', result)
            setAlert(true);
            setErrorType("success");
            getBusinessHours();
            setMessage("Store opening hours saved successfully");
        } catch (error) {
            const { message } = error as Error;
            setIsLoading(false);
            setErrorType("error");
            setAlert(true);
            setMessage(message);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getBusinessHours = useCallback(() => {
        // setIsLoading(true);
        console.log('businessHours', businessHours)
        searchWithPKSK('store-setting', 'STORE-SETTING', `ACTIVE#${storeId}`,)
        .then((res) => {
            if (res) {
                console.log('res', res?.hits[0]?._source?.businessHours)
                if(res?.hits){
                    const hours = res?.hits[0]?._source?.businessHours
                    // setBusinessHours(res?.hits[0]?._source?.businessHours);
                    const formattedHours = Object.keys(hours).reduce((acc, day) => {
                        acc[day] = {
                          open: hours[day].open,
                          slots: hours[day].slots.map((slot: any) => ({
                            // start: new Date(slot.start),
                            // end: new Date(slot.end),
                            start: moment(slot.start).tz("Africa/Johannesburg").toDate(),
                            end: moment(slot.end).tz("Africa/Johannesburg").toDate(),
                          })),
                        };
                        return acc;
                      }, {} as Record<string, { open: boolean; slots: { start: Date; end: Date }[] }>);
                    
                      console.log('formattedHours', formattedHours)
                      setBusinessHours(formattedHours);
                }
                // setIsLoading(false);
            //   const result = StoreResponse(res);
            //   setStore(result?.modifiedData?.[0] || {});
            }
        })
        .catch((err) => {
            console.error(err);
            setIsLoading(false);
            toast.error("Failed to fetch store data");
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
      console.log('businessHours', businessHours)
        getBusinessHours();
    }, [getBusinessHours]);

  return (
    <div className="container mt-4 border p-4 border-grey rounded white_bg">
        <>
        {alert && message && (
            <Toast type={errorType === 'error' ? "error" : "success"} message={message} />
        )}
        <LoadingOverlay isVisible={isLoading} message="Please wait..." />

        <div className="row mb-2 ">
            <div className="heading-title col-10">
                <h1>Opening Hours</h1>
            </div>
            <div className="col-2 float-right mt-1 ">
            <button onClick={saveBusinessHours} className="btn btn-outline-success w-100" disabled={isLoading}>
                Save Hours
            </button>
            </div>
            <hr className="mt-2"></hr>
        </div>

        <div className="row">
            <div className="col-2">
                <h5> Days</h5>
            </div>
            <div className="col-2">
                <h5> Day Status</h5>
            </div>
            <div className="col-2">
                <h5> Start Time</h5>
            </div>
            <div className="col-3">
                <h5> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;Close Time</h5>
            </div>
            <div className="col-1">
                <h5>&nbsp; Action </h5>
            </div>
        </div>
        
  
      {daysOfWeek.map((day) => (
        <div key={day} className="row mb-3 mt-3">
          <div className="col-2">
            <span className="fw-bold">{day}</span>
          </div>
          <div className="col-2">
            <button
              className={`btn btn-${businessHours[day].open ? "success btn-outline-success" : "danger"} w-100`}
              onClick={() => toggleOpenStatus(day)}
            >
              {businessHours[day].open ? "Open" : "Closed"}
            </button>
          </div>

         
          <div className="col-6">
            {businessHours[day].open &&
              businessHours[day].slots.map((slot, index) => (
                <div key={index} className="row mb-2">
                  <div className="col-5">
                    <DatePicker
                      selected={slot.start}
                      onChange={(date) => updateTimeSlot(day, index, "start", date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Start Time"
                      dateFormat="h:mm aa"
                      className="form-control-opening"
                      placeholderText="Start Time"
                    />
                  </div>
                  <div className="col-5">
                    <DatePicker
                      selected={slot.end}
                      onChange={(date) => updateTimeSlot(day, index, "end", date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="End Time"
                      dateFormat="h:mm aa"
                      className="form-control-opening"
                      placeholderText="End Time"
                    />
                  </div>
                  <div className="col-2">
                    {index > 0 && businessHours[day].slots.length > 1 && (
                      <button className="btn btn-link text-decoration-none" onClick={() => removeTimeSlot(day, index)}>
                        <p style={{color:"red"}}>Remove</p> 
                      </button>
                    )}
                    {index === 0 && businessHours[day].open && (
                      <button className="btn btn-link text-decoration-none" onClick={() => addTimeSlot(day)}>
                        Add&nbsp;hours
                      </button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
      
      </>
    </div>
  );
};

export default BusinessHours;


